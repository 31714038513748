import { Component, OnInit } from '@angular/core';
import {IonicModule, NavController} from "@ionic/angular";
import {ActivatedRoute} from "@angular/router";
import {StatisticsComponent} from "../../components/statistics/statistics.component";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {HomepagePageRoutingModule} from "./homepage-routing.module";

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.page.html',
  styleUrls: ['./homepage.page.scss'],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    HomepagePageRoutingModule,
    StatisticsComponent
  ],
})
export class HomepagePage implements OnInit {
  type: string;
  offers_count: string;

  constructor(public navCtrl: NavController, private route: ActivatedRoute,) {
    this.offers_count = this.route.snapshot.params['offersCount'];
  }

  ngOnInit() {
  }

  profile(){
    this.navCtrl.navigateRoot('/profile/'+this.offers_count)
  }

  offers(){
    this.type = 'offers';
    this.navCtrl.navigateRoot('/campaign-offers/offers');
  }

  archive(){
    this.type = 'old';
    this.navCtrl.navigateRoot('/campaign-offers/old');
  }

  home() {
    this.navCtrl.navigateRoot('homepage')
  }

}
