import { Component, OnInit } from '@angular/core';
import LogRocket from 'logrocket';
import {NavController, AlertController, IonicModule} from '@ionic/angular';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {ValidatorPasswordVerify} from './password_validator';
import {EventsService} from '../../services/events.service';
import {ApiService, Settings, StorageService} from "../../services/providers.service";
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import { environment } from 'custom/environment'
import {CommonModule} from "@angular/common";
import {SignupPageRoutingModule} from "./signup-routing.module";


@Component({
  selector: 'app-signup',
  templateUrl: './signup.page.html',
  styleUrls: ['./signup.page.scss'],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    SignupPageRoutingModule,
    TranslateModule
  ],
})
export class SignupPage implements OnInit {

  formgroup: FormGroup;
  type: string = 'password';
  tnc: boolean = false;
  language: string = 'en';
  country: string = '';
  passwordVisible: boolean = false;
  supportEmail: string =  environment.supportEmail;
  //agent_id: any = this.navParams.get('agent_id');
  //user_id: any = this.navParams.get('user_id');
  languageSelectOptions:any;

  constructor(public navCtrl: NavController,
              public formbuilder: FormBuilder,
              private translate: TranslateService,
              private alertCtrl: AlertController,
              private apiService: ApiService,
              private storage: StorageService,
              private events: EventsService) {

      this.formgroup = this.formbuilder.group({
          email: [null, Validators.email],
          password: ['', [Validators.minLength(6), Validators.required]],
          password_verify: [''],
          agent_id: [null],
          approved_by: [null]
      }, {
          validator: ValidatorPasswordVerify.check
      });
      this.languageSelectOptions = {
          title: this.translate.instant('change-language'),
          mode:'ios'
      }
  }

  ngOnInit() {
      window["app_ga"]('send', 'pageview', "ob_signup");
      window["app_ga"]('send', 'event', "Signup", "Entered");
      // if (this.agent_id) {
      //     console.log("Recognized Agent id:" + this.agent_id);
      //     this.formgroup.controls.agent_id.patchValue(this.agent_id);
      // }
      // if (this.user_id) {
      //     this.formgroup.controls.approved_by.patchValue(this.user_id);
      // }

      this.replaceEmailSpaces();
      setTimeout(()=> {this.storage.get('language').then(su => {if (su){ this.language = su;} else {setTimeout(()=> {this.storage.get('language').then(su => {if (su){ this.language = su;}} )},1500);}})},500);
  }

  signupWithEmail() {
    console.log('hey')
      this.apiService.signup(this.formgroup.getRawValue()).then(
          (success: any) => {
            console.log(success)
              if (success.email) {
                  window["app_ga"]('send', 'event', "Signup", "Completed");
                  LogRocket.identify(success.email);
                  LogRocket.track('Signup Success');
                  this.storage.pStore('email', success.email).then(su => {
                      this.storage.pStore('signupStep', 'signup-details').then(su2 => {
                        this.navCtrl.navigateRoot('signup-details', {state: {email: success.email}}); //redirect to signup details
                      });
                  });
              }
          },
          async error2 => {
            console.log(error2)
              let title = this.translate.instant('signup.errors.' + error2.error.error + '.title'),
                  message = this.translate.instant('signup.errors.' + error2.error.error + '.message'),
                  confirm = this.translate.instant('signup.errors.' + error2.error.error + '.confirm'),
                  cancel = this.translate.instant('signup.errors.' + error2.error.error + '.cancel'),
                  alert = await this.alertCtrl.create({
                      header: title,
                      subHeader: message,
                      buttons: [
                          {text: cancel, role: 'cancel'},
                          {
                              text: confirm,
                              handler: () => {
                                  switch (error2.error.error) {
                                      case "EMAIL_EXIST":
                                          window["app_ga"]('send', 'event', "Signup", "Exists");
                                          this.navCtrl.navigateRoot('/signin');
                                          break;
                                      case "IG_LOGIN_ONLY":
                                          this.navCtrl.navigateForward('/password-reminder');
                                          //window.location.href = "https://api.instagram.com/oauth/authorize/?client_id=" + Settings.INSTAGRAM_APP_ID + "&redirect_uri=" + Settings.INSTAGRAM_REDIRECT_URL + "?type=instagram&response_type=token";
                                  }
                              }
                          }
                      ],
                      mode: 'ios'
                  });
              alert.present();
          }
      );
  }

  changeLanguage() {
      this.events.publish("translations:change", this.language);
      this.languageSelectOptions.title = this.translate.instant('change-language');
  }

  showPassword() {
      this.passwordVisible = !this.passwordVisible;
      if (this.passwordVisible) {
          this.type = 'text'

      } else {
          this.type = 'password'
      }
      //this.updatePassword();
  }

  updatePassword() {
      if (this.passwordVisible) {
          let password = this.formgroup.controls['password'].value;
          this.formgroup.controls['password_verify'].patchValue(password)
      }
  }

  private replaceEmailSpaces(): void {
    this.formgroup.valueChanges
      .subscribe(param => {
        if (param.email && param.email.includes(' ')) {
          this.formgroup.patchValue({email: param.email.replace(' ', '')});
        }
      });
  }

}
