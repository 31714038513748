import LogRocket from 'logrocket';
import {Component} from '@angular/core';
import {AlertController, IonicModule, NavController, Platform, ToastController} from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
import {StatusBar} from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';
import { StorageService } from './services/storage.service';
import { ApiService } from './services/api.service';
import { EventsService } from './services/events.service';
import {Storage} from '@ionic/storage-angular';
import $ from 'jquery';
import {TranslateService} from '@ngx-translate/core';
import {Location} from '@angular/common';
import OneSignal from 'onesignal-cordova-plugin';
import {environment } from '../../custom/environment';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  imports: [
    IonicModule
  ],
  standalone: false,
})
export class AppComponent {
    rootPage: any = 'SigninPage';

    constructor(public platform: Platform,
                private apiService: ApiService,
                private storage: StorageService,
                private events: EventsService,
                private _storage: Storage,
                public nav: NavController,
                public  alertCtrl: AlertController,
                public  toastCtrl: ToastController,
                private location: Location,
                private translate: TranslateService) {

        events.subscribe('translations:change', (lng) => {
            this.changeLanguage(lng);
        });
        events.subscribe('app:login', (access_token: string) => {
            console.log('Found access token');

            this.doLogin(access_token, 'email');
        });
        platform.ready().then(async () => {
            SplashScreen.hide();
            LogRocket.init('yvykj7/prod-1zraq');
            console.log('LogRocket init');

            if (Capacitor.isNativePlatform()) {
              this.OneSignalInit();
              console.log('OneSignal init');
            }
            if(!Capacitor.isNativePlatform() && this.platform.is('desktop')){
              $('ion-app').parent().addClass('iphonebg');
              $('ion-app').addClass('add_pc_bounds');
            }
            if (Capacitor.isNativePlatform() && this.platform.is('android')) {
               StatusBar.setOverlaysWebView({overlay: false});
               StatusBar.setBackgroundColor({color:environment.primaryColor});
            }
            await this._storage.create();
            if(!Capacitor.isNativePlatform() && this.platform.is('desktop')) {
              var getParameters = [];
              if (document.URL.indexOf("?") > -1) {
                  let url = document.URL;
                  let urlParams = url.split("?")[1];
                  if (document.URL.indexOf("&") > -1){
                      let params = urlParams.split("&");
                      for (var p in params) {
                          let paramKeyVal = params[p].split("=");
                          getParameters[paramKeyVal[0]] = paramKeyVal[1]?paramKeyVal[1]:true;
                      }
                  } else {
                      let parts = urlParams.split("=");
                      getParameters[parts[0]] = parts[1];
                  }
              }
              if( getParameters['dr']){
                  $('app-root').before('<div class="custom-splash"><div class="loader-icon"><div class="circle"><div class="dial"></div></div></div></div>')
                  translate.setDefaultLang('en');
                  translate.use('en');
                  console.log("AUTO LOGIN");
                  this.loginWithEmailAs({lid: getParameters['dr'], c: getParameters['c'], cid: getParameters['cid']});

              } else if(getParameters['logout']) {
                  let language = 'en';
                  this.storage.get('language').then(
                      su2 => {
                          if (su2) {
                              language = su2;
                          }
                          this.storage.clear().then(su => {
                              this.storage.pStore('language', language).then(su => {
                                  this.nav.navigateRoot('/signin');
                              });
                          });
                      });
              }
            }
            this.storage.get('language').then(language => {
              if (!language) {
                  $.get('https://ipapi.co/jsonp', function() {}, 'jsonp').then(function(resp) {
                      switch (resp.country) {
                          case 'ES':
                              storage.store('language', 'es');
                              translate.setDefaultLang('es');
                              translate.use('es');
                              break;
                          case 'DE':
                              storage.store('language', 'de');
                              translate.setDefaultLang('de');
                              translate.use('de');
                              break;
                          case 'CA':
                              storage.store('country', 'CA');
                              storage.store('language', 'en');
                              translate.setDefaultLang('en');
                              translate.use('en');
                              break;
                          case 'RU':
                            storage.store('country', 'RU');
                            storage.store('language', 'ru');
                            translate.setDefaultLang('ru');
                            translate.use('ru');
                            break;
                          case 'IL':
                            storage.store('country', 'IL');
                            storage.store('language', 'he');
                            translate.setDefaultLang('he');
                            translate.use('he');
                            $(document.body).addClass('rtl');
                            break;
                          case 'IQ':
                          case 'IR':
                            storage.store('country', resp.country);
                            storage.store('language', 'ku');
                            translate.setDefaultLang('ku');
                            translate.use('ku');
                            $(document.body).addClass('rtl');
                            break;
                          default:
                              storage.store('language', 'en');
                              translate.setDefaultLang('en');
                              translate.use('en');
                              break;
                      }
                      setTimeout(()=>{events.publish('change:language');}, 100);
                      setTimeout(()=>{events.publish('change:type');}, 100);

                  }, (err) => {
                          storage.store('language', 'en');
                          translate.setDefaultLang('en');
                          translate.use('en');
                          setTimeout(()=>{events.publish('change:language');}, 100);
                          setTimeout(()=>{events.publish('change:type');}, 100);
                      });

              } else {
                if (language == 'he' || language == 'ku' || language == 'ar') {
                  $(document.body).addClass('rtl');
                } else {
                  $(document.body).removeClass('rtl');
                }
                translate.setDefaultLang(language);
                translate.use(language);
              }
          });
          storage.get('currentUser').then(
              (res0) => {
                  if (res0) {
                      this.doApiLogin();
                      storage.get('currentUser').then(
                          (res) => {
                              const leader = JSON.parse(res);
                              LogRocket.identify(leader.email, {
                                  fullname: leader.fullname
                              });
                              LogRocket.track('Entrance Success');
                          }
                      );
                  }
                  else {
                      this.storage.get('signupStep').then(su => {
                          if (su) {
                              switch (su) {
                                  case 'signup-details':
                                      this.nav.navigateRoot('/signup-details');
                                      break;
                                  case 'ig-verify':
                                    this.nav.navigateRoot('/ig-verify');
                                    break;
                              }
                          } else {
                              this.nav.navigateRoot('/signin');
                          }

                      });
                  }
              }
          ).catch(e => {
          });
        });
        this.subscribeEvents();
    }

    // Call this function when your app starts
    private OneSignalInit(): void {
      // Uncomment to set OneSignal device logging to VERBOSE
      // OneSignal.setLogLevel(6, 0);

      // NOTE: Update the setAppId value below with your OneSignal AppId.
        window['plugins'].OneSignal.setAppId(environment.oneSignalId);
        window['plugins'].OneSignal.setNotificationOpenedHandler(function(jsonData) {
          console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
      });
        window['plugins'].OneSignal.setNotificationWillShowInForegroundHandler(function(notificationReceivedEvent) {
        notificationReceivedEvent.complete(notificationReceivedEvent.getNotification());
      })

      // iOS - Prompts the user for notification permissions.
      //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 6) to better communicate to your users what notifications they will get.
        window['plugins'].OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
          console.log("User accepted notifications: " + accepted);
      });
    }


    private changeLanguage(language: string) {
      this.translate.setDefaultLang(language);
      this.translate.use(language);
      this.storage.store('language', language);
      if (language == 'he' || language == 'ku' || language == 'ar') {
        $(document.body).addClass('rtl');
      } else {
        $(document.body).removeClass('rtl');
      }
  }


    private async doLogin(access_token, connected_social) {
        let clanguage = 'en',
            campaign_id = await this.storage.get('campaign_id');
        this.storage.get('language').then(su2 => {
            if (su2){
              if (su2 == 'he' || su2 == 'ku' || su2 == 'ar') {
                $(document.body).addClass('rtl');
              } else {
                $(document.body).removeClass('rtl');
              }
                clanguage = su2;
            }
        });

        console.log('Started Login process');
        this.storage.clear().then(su0 => {
            this.storage.pStore('social_network', connected_social).then(su1 => {
                this.storage.pStore('access_token', access_token).then(
                    su2 => {
                        console.log('Access token stored, commencing API login');
                        this.storage.store('campaign_id', campaign_id);
                        this.storage.store('language', clanguage);
                        this.doApiLogin();
                    },
                    er2 => {
                        console.log('Access token couldn\'t be stored');
                        console.log(er2);
                    }
                ).catch(
                    erCatch2 => {
                        console.log('Error using storage');
                        console.log(erCatch2);
                    }
                );
            }, async er => {
                LogRocket.track('QuotaExceededError');
                const alert = await this.alertCtrl.create({
                    header: this.translate.instant('signin.errors.quota.title'),
                    subHeader: this.translate.instant('signin.errors.quota.message'),
                    buttons: [this.translate.instant('signin.errors.quota.button')],
                    mode: 'ios'
                });
                await alert.present();
                console.log('Error writing cache');
                console.log(er);
            }).catch(erCatch1 => {
                console.log('Error writing cache - CATCH');
                console.log(erCatch1);
            });
        });
    }

    private async doApiLogin() {
        this.apiService.login().then(
              (success: any) => {
                console.log('Login success', success);
                this.apiService.setLeader(success.message);
                try {
                    //LogRocket.init(Settings.LOG_ROCKET);
                    LogRocket.identify(success.message.email, {
                        fullname: success.message.fullname
                    });
                    LogRocket.track('Login Success');
                } catch (err) {
                }
                if (success.approved) {
                    if ( ! Capacitor.isNativePlatform()) {
                      $('.custom-splash').remove();
                    }
                    let rootLocation = '/campaign-offers';
                    if (this.location.path().includes('/campaign/') || this.location.path().includes('/media/')){
                      rootLocation = this.location.path();
                    }
                    this.storage.get('campaign_id').then(su2 => {
                      if (su2){
                          this.nav.navigateRoot('/campaign/'+su2);
                      } else {
                        if (Capacitor.isNativePlatform()) {
                          let that = this;
                            window['plugins'].OneSignal.setNotificationOpenedHandler(function(su: any) {
                            // do something when a notification is opened
                            if (typeof su.notification.additionalData.campaign_id !== "undefined") {
                              let campaign_id = su.notification.additionalData.campaign_id
                              if (typeof su.notification.additionalData.is_chat !== "undefined"){
                                that.nav.navigateRoot('/campaign/'+campaign_id, {state: {isChat: true}});
                              } else {
                                that.nav.navigateRoot('/campaign/'+campaign_id);
                              }
                            }
                            if (typeof su.notification.additionalData.post_id !== "undefined") {
                              that.nav.navigateRoot('/media/'+su.notification.additionalData.post_id);
                            }
                          });
                        }
                        this.nav.navigateRoot(rootLocation);
                      }
                  });

                }
                else {
                    let resumed = false;
                    this.platform.resume.subscribe(() => {
                        this.apiService.login().then(
                            (success2: any) => {
                                resumed = true;
                                if (success2.approved){
                                    this.nav.navigateRoot('/campaign-offers');
                                    this.platform.resume.unsubscribe();
                                } else {
                                    this.nav.navigateRoot('/pending-approval');
                                }
                            }
                        );
                    });
                    if ( ! resumed){
                        this.nav.navigateRoot('/pending-approval');
                    }
                }
            },
            error2 => {
                if (error2.error.error=='API_TOKEN_NOT_EXIST') {
                    this.storage.clear().then(su => {
                      this.nav.navigateRoot('/signin');
                    });
                }
            }
        );
      };

      private subscribeEvents() {
        // hook to the global (response) error event
        this.events.subscribe('RESPONSE:ERROR', (error: any) => {
            // prompt the current error
            this._showAlert(error.error, error.message);
        });

        this.events.subscribe('RESPONSE:MESSAGE', (data) => {
          this._showAlert(data.error, data.message);
        });
    }

    private async _showAlert(header, message) {

        const alert = await this.alertCtrl.create({
            header: header,
            message: message,
            buttons: [this.translate.instant('ok')],
            mode: 'ios'
        });

        // show the alert
        await alert.present();

    }

    private async loginWithEmailAs(data) {
      this.storage.clear().then(su => {
        this.apiService.signinas({lid:data.lid, cd:data.c}).then(
          (response: any) => {
            if (data.cid) {
              this.storage.pStore('campaign_id', data.cid).then(su => {
                  this.events.publish('app:login', response.message);
              });
          } else {
              this.events.publish('app:login', response.message);
          }
        },
          async (error2)=>{
            console.log(error2);
              const alert = await this.alertCtrl.create({
                      header: 'Operation failed',
                      subHeader: 'Please try again or call support',
                      buttons: [
                          {text: 'OK', role: 'cancel'},
                      ],
                      mode: 'ios'
                  });
              await alert.present();
          }
        );
      });
    }
};
