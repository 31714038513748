/* src/app/pages/campaign-offers/campaign-offers.page.scss */
.scroll-content {
  background-color: #f9f9f9;
  margin-bottom: 60px;
}
.tab-main {
  left: 0;
  top: 0;
  position: absolute;
  z-index: 0;
  display: block;
  width: 100%;
  height: 100%;
}
.tabbar {
  z-index: 10;
  display: flex;
  width: 100%;
  position: sticky;
  top: 0;
  background-color: #fff;
}
.tabbar::after {
  left: 0;
  bottom: -5px;
  background-position: left 0 top -2px;
  position: absolute;
  width: 100%;
  height: 5px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAHBAMAAADzDtBxAAAAD1BMVEUAAAAAAAAAAAAAAAAAAABPDueNAAAABXRSTlMUCS0gBIh/TXEAAAAaSURBVAjXYxCEAgY4UIICBmMogMsgFLtAAQCNSwXZKOdPxgAAAABJRU5ErkJggg==);
  background-repeat: repeat-x;
  content: "";
}
.tab-button {
  margin: 0;
  text-align: center;
  border-radius: 0;
  position: relative;
  z-index: 0;
  display: flex;
  overflow: hidden;
  flex: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;
  border: 0;
  text-decoration: none;
  background: 0 0;
  cursor: pointer;
  color: #d9d9d9;
  height: 56px;
}
.tab-button .tab-button-text {
  text-transform: uppercase;
  font-weight: 800;
}
.tab-button[aria-selected=true] {
  position: relative;
  color: #000;
}
.tab-button[aria-selected=true]:after {
  width: 35%;
  border-color: var(--primary);
  border: none;
  border-bottom: solid 4px var(--primary);
  position: absolute;
  content: " ";
  bottom: 0;
}
.no_campaigns {
  top: 20%;
  position: relative;
  opacity: 0.8;
  max-width: 80%;
  margin: 20% 0 0 10%;
}
ion-avatar {
  width: 38px;
  height: 38px;
}
.item {
  padding-left: 16px;
  padding-right: 0;
  margin: 0;
  position: relative;
  font-size: 1.6rem;
  font-weight: 400;
  text-transform: none;
  color: #000;
  box-shadow: none;
  padding: 0;
  text-align: initial;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 4.4rem;
  border-width: 0;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  padding: 0;
  padding-top: 20px;
  margin-bottom: 10px !important;
}
ion-item .item-inner {
  margin: 0;
  padding: 0;
  display: flex;
  overflow: hidden;
  flex: 1;
  align-self: stretch;
  border-width: 0;
}
ion-item .item-inner .input-wrapper {
  display: flex;
  overflow: hidden;
  flex: 1;
  border-width: 0;
  align-self: stretch;
  text-overflow: ellipsis;
}
ion-item .label-md {
  margin: 0;
  border-width: 0;
}
ion-item .cover {
  width: 100%;
}
ion-item ion-thumbnail {
  margin: 0 !important;
  left: 15px;
  top: 0;
  min-width: 80px;
  min-height: 80px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
ion-item .input-wrapper h2 {
  max-width: calc(100% - 80px);
  text-overflow: ellipsis;
  overflow: hidden;
}
ion-item h2 {
  color: var(--primary);
}
ion-item h2,
ion-item p {
  margin-left: 105px;
  margin-bottom: 5px;
  text-align: left;
}
ion-item status-actions div {
  margin: 0;
  display: inline-block;
}
.ion-md-alert {
  margin-right: 20px;
  color: #fff;
  font-size: 2rem;
}
.toolbar-background {
  background: var(--primary);
}
video.no-offers {
  position: fixed;
  z-index: -1;
  opacity: 1;
  height: 100%;
  filter: hue-rotate(185deg);
  top: -9999px;
  bottom: -9999px;
  left: -9999px;
  right: -9999px;
  margin: auto;
}
h3.nolist-title {
  font-weight: 800;
  font-size: 1rem;
  width: 80%;
  margin: 30% auto;
  text-align: center;
  color: var(--accent);
}
ion-footer {
  height: calc(70px + env(safe-area-inset-bottom));
  background-color: var(--primary);
}
.badge {
  border-radius: 3px;
  background-color: #6d747e;
  padding: 7px 12px;
  font-weight: 300;
}
.badge.post-declined {
  background-color: #f30d0d;
  margin-top: -25px;
  margin-right: 5px;
}
.image-container {
  position: relative;
  width: 100%;
}
.cover-img {
  width: 100%;
  height: 100%;
}
.instagram-chip {
  background-image:
    linear-gradient(
      45deg,
      #feda75,
      #fa7e1e,
      #d62976,
      #962fbf,
      #4f5bd5) !important;
  -webkit-background-clip: text;
}
.logo-img {
  position: absolute;
  top: -40px;
  left: 20px;
  width: 80px;
  height: 80px;
  border-radius: 10%;
  border: 3px solid lightgray;
}
.campaign-title {
  position: absolute;
  top: -40px;
  left: 105px;
  z-index: 3;
  max-width: calc(100% - 105px);
  text-align: left;
}
.floating-card {
  position: relative;
  margin: 50px 10px 60px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
}
.floating-card:hover {
  transform: translateY(-2px);
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
}
/*# sourceMappingURL=campaign-offers.page.css.map */
