import {AbstractControl} from '@angular/forms';

export class ValidatorPasswordVerify {
    static check (control: AbstractControl) {
        let password = control.get('password').value;
        let confirmPassword = control.get('password_verify').value;
        if((password != confirmPassword)) {
            control.get('password_verify').setErrors( {MatchPassword: true} )
        } else {
            return null
        }
    }
}