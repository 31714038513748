<ion-header>
  <div style="text-align: center; display: flex; justify-content: space-between">
    <div style="margin: 10px 0 10px 10px;">
      <h3 style="color: black; font-weight: 800; font-size: 15px">
        {{ 'profile.title' | translate }}
      </h3>
    </div>

    <div>
      <img style="height: 50px; margin: 10px 0 10px 0;" src="../../../custom/logo_svg.png" alt="logo">
      <img style="height: 50px; margin: 10px 5px 10px 0;" src="../../../custom/logo_text.png" alt="logo">
    </div>
  </div>
</ion-header>

<ion-content>
  <!-- Custom Tabs -->
  <div class="tabbar show-tabbar" role="tablist">
    <a
            class="tab-button has-title has-title-only"
            [ngClass]="{ 'active-tab': selectedTab === 'general' }"
            (click)="selectTab('general')">
      <span class="tab-button-text">General</span>
      <div class="button-effect"></div>
    </a>
    <a
            class="tab-button has-title has-title-only"
            [ngClass]="{ 'active-tab': selectedTab === 'socials' }"
            (click)="selectTab('socials')">
      <span class="tab-button-text">Socials</span>
      <div class="button-effect"></div>
    </a>
    <a
            class="tab-button has-title has-title-only"
            [ngClass]="{ 'active-tab': selectedTab === 'payments' }"
            (click)="selectTab('payments')">
      <span class="tab-button-text">Payments</span>
      <div class="button-effect"></div>
    </a>
    <div class="tab-highlight"></div>
  </div>

  <div *ngIf="selectedTab === 'general'" style="width: 100%" class="tab-content">
    <div class="top-section" style="text-align: center">
      <div class="visual"></div>
      <div class="image">
        <img src="{{user.profile_picture}}" />
      </div>
      <div class="spacer"></div>
    </div>

    <form [formGroup]="formgroup">
      <ion-list inset lines="none" class="profile-list">
        <ion-item class="form-item">
          <ion-label position="floating" color="accent" >{{ 'full-name' | translate }}</ion-label>
          <ion-input type="text" formControlName="fullname"></ion-input>
        </ion-item>
        <ion-item class="form-item">
          <ion-label position="floating" color="accent" >{{ 'email_address' | translate }}</ion-label>
          <ion-input type="text" formControlName="email"></ion-input>
        </ion-item>
        <ion-item *ngIf="formgroup.controls.fullname.errors  && formgroup.controls.fullname.touched">
          <p class="error" >{{ 'signup-details.name_error' | translate }}</p>
        </ion-item>
        <ion-item class="form-item">
          <ion-label position="floating" color="accent" >{{ 'profile.address' | translate }}</ion-label>
          <ion-input type="text" formControlName="address"></ion-input>
        </ion-item>
        <ion-item *ngIf="formgroup.controls.address.errors  && formgroup.controls.address.touched">
          <p class="error" >{{ 'profile.address-error' | translate }}</p>
        </ion-item>
        <ion-item class="form-item">
          <ion-label position="floating" color="accent" >{{ 'profile.city' | translate }}</ion-label>
          <ion-input type="text" class="addressAC" formControlName="city"></ion-input>
        </ion-item>
        <ion-item *ngIf="formgroup.controls.city.errors  && formgroup.controls.city.touched">
          <p class="error" >{{ 'profile.city-error' | translate }}</p>
        </ion-item>
        <ion-item class="form-item">
          <ion-label position="floating" color="accent" >{{ 'profile.zip' | translate }}</ion-label>
          <ion-input type="text" formControlName="postal_code"></ion-input>
        </ion-item>
        <ion-item *ngIf="formgroup.controls.postal_code.errors  && formgroup.controls.postal_code.touched">
          <p class="error" >{{ 'profile.zip-error' | translate }}</p>
        </ion-item>
        <ion-item class="form-item">
          <ion-label position="floating" color="accent" >{{ 'profile.id' | translate }}</ion-label>
          <ion-input type="number" formControlName="uid"></ion-input>
        </ion-item>
        <ion-item *ngIf="formgroup.controls.uid.errors  && formgroup.controls.uid.touched">
          <p class="error" >{{ 'profile.id-error' | translate }}</p>
        </ion-item>
        <ion-item class="form-item">
          <ion-label position="floating" color="accent">{{ 'birthdate' | translate }}</ion-label>
          <ion-input
                  value="{{ date | date: 'dd/MM/yyyy' }}"
                  id="date"
          ></ion-input>
          <ion-popover trigger="date" size="cover">
            <ng-template>
              <ion-datetime
                      presentation="date"
                      [(ngModel)]="date"
                      formControlName="birthdate"
              ></ion-datetime>
            </ng-template>
          </ion-popover>
        </ion-item>
        <ion-item *ngIf="formgroup.controls.birthdate.errors  && formgroup.controls.birthdate.touched">
          <p class="error" >{{ 'signup-details.birthday_error' | translate }}</p>
        </ion-item>
        <ion-item class="form-item">
          <ion-label position="floating" color="accent">{{ 'language' | translate }}</ion-label>
          <ion-select (ionChange)="changeLanguage()" okText="{{ 'ok' | translate }}" cancelText="{{ 'cancel' | translate }}" [ngModelOptions]="{standalone: true}" [(ngModel)]="language" [interfaceOptions]="{header:'Change Language', mode:'ios'}" >
            <ion-select-option value="en">English</ion-select-option>
            <ion-select-option value="he">עברית</ion-select-option>
            <ion-select-option value="es">Español</ion-select-option>
            <ion-select-option value="de">Deutsch</ion-select-option>
            <ion-select-option value="ku">کورد</ion-select-option>
            <ion-select-option value="ar">اَلْعَرَبِيَّةُ</ion-select-option>
            <ion-select-option value="ru">Русский</ion-select-option>
          </ion-select>
        </ion-item>
        <div class="ion-custom-item">
          <ion-label color="accent">{{ 'interests-term' | translate }} {{ 'signup-details.interests-info' | translate }}</ion-label>
          <ion-slides pager class="interests">
            <ion-slide>
              <div class="slide-zoom">
                <div class="icon-set">
                  <ng-container *ngFor="let i of interests; let ind=index;">
                    <div *ngIf="ind>-1 && ind<4" (click)="updateInterestSelection(ind)">
                      <div class="icon icon-{{i.value}}" [ngClass]="i.selected && 'icon-selected' || ''"></div>
                      <span>{{i.title}}</span>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ion-slide>
            <ion-slide>
              <div class="slide-zoom">
                <div class="icon-set">
                  <ng-container *ngFor="let i of interests; let ind=index;">
                    <div *ngIf="ind>3 && ind<8" (click)="updateInterestSelection(ind)">
                      <div class="icon icon-{{i.value}}" [ngClass]="i.selected && 'icon-selected' || ''"></div>
                      <span >{{i.title}}</span>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ion-slide>

            <ion-slide>
              <div class="slide-zoom">
                <div class="icon-set">
                  <ng-container *ngFor="let i of interests; let ind=index;">
                    <div *ngIf="ind>7 && ind<12" (click)="updateInterestSelection(ind)">
                      <div class="icon icon-{{i.value}}" [ngClass]="i.selected && 'icon-selected' || ''"></div>
                      <span >{{i.title}}</span>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ion-slide>
          </ion-slides>
        </div>
        <div class="ion-text-center" style="display: flex; flex-direction: row">
          <ion-button [disabled]="!formgroup.valid || missing_interest" style="background: var(--accent); border-radius: 10px; width: 50%" expand="block" color="red"  (click)="updateDetails()">{{ 'profile-details.button' | translate }}</ion-button>
          <!--          <ion-button *ngIf="isIOS" class="delete-btn" color="danger"  (click)="deleteLeader()">{{ 'profile-details.delete-button' | translate }}</ion-button>-->
          <ion-button style="width: 50%" expand="block" class="delete-btn" color="danger"  (click)="deleteLeader()">{{ 'profile-details.delete-button' | translate }}</ion-button>
        </div>
        <div class="ion-text-center" style="display: flex; flex-direction: column">
          <ion-button style="background: var(--accent); border-radius: 10px; width: 99%" expand="block" color="red" (click)="logout()">Log out</ion-button>
        </div>
      </ion-list>
    </form>
  </div>

  <div *ngIf="selectedTab === 'socials'" style="width: 100%" class="tab-content">
    <ion-list inset lines="none" class="profile-list">

      <!-- Instagram Connect Button -->
      <div class="social-connect">
        <div style="display: flex; flex-direction: row">
          <p style="font-size: 15px; font-weight: 800;
                     background: linear-gradient(45deg, #feda75, #fa7e1e, #d62976, #962fbf, #4f5bd5);
                     -webkit-background-clip: text;
                     color: transparent;">
            <ion-icon name="logo-instagram" style="font-size: 15px; margin-right: 5px;color: #D62976FF;"></ion-icon>
            Instagram
          </p>
        </div>
        <ion-button fill="outline" class="connect-btn" (click)="openModal('tiktok')">
          Connected
          <ion-icon name="checkmark-circle-outline" style="font-size: 15px; color: #00FF00; margin-left: 5px"></ion-icon>
        </ion-button>
      </div>

      <!-- TikTok Connect Button -->
      <div class="social-connect">
        <div style="display: flex; flex-direction: row">
          <p style="font-size: 15px; font-weight: 800;
            background: linear-gradient(45deg, #25F4EE, #FE2C55, #000000);
            -webkit-background-clip: text;
            color: transparent;">
            <ion-icon name="logo-tiktok" style="font-size: 15px; margin-right: 5px; color: #a61028;"></ion-icon>
            TikTok
          </p>
        </div>
        <ion-button fill="outline" class="connect-btn" (click)="openModal('tiktok')">
          Connect
          <ion-icon name="arrow-forward" style="font-size: 15px; color: #000000;"></ion-icon>
        </ion-button>
      </div>

      <!-- YouTube Connect Button -->
      <div class="social-connect">
        <div style="display: flex; flex-direction: row">
          <p style="font-size: 15px; font-weight: 800;
            background: linear-gradient(45deg, #FF0000, #CC0000, #990000);
            -webkit-background-clip: text;
            color: transparent;">
            <ion-icon name="logo-youtube" style="font-size: 15px; margin-right: 5px; color: #FF0000;"></ion-icon>
            YouTube
          </p>
        </div>
        <ion-button fill="outline" class="connect-btn" (click)="openModal('youtube')">
          Connect
          <ion-icon name="arrow-forward" style="font-size: 15px; color: #000000;"></ion-icon>
        </ion-button>
      </div>

      <p style="color: lightgray; font-size: 12px; text-align: center">You only provide your usernames on these social platforms to link them within our app. We do not access or interact with your accounts in any way.</p>

    </ion-list>
  </div>

  <!-- TikTok Modal -->
  <ion-modal [isOpen]="isTikTokModalOpen" (didDismiss)="closeModal('tiktok')">
    <ng-template>
      <div class="modal-content">
        <h3>TikTok</h3>
        <form [formGroup]="formgroup">
          <ion-item class="bordered">
            <ion-label position="floating" color="accent">TikTok Username</ion-label>
            <ion-input type="text" formControlName="tiktok_name"></ion-input>
          </ion-item>
          <div class="ion-text-center">
            <ion-button
                    [disabled]="!formgroup.valid"
                    style="background: var(--accent); border-radius: 10px;"
                    expand="block"
                    color="tertiary"
                    (click)="updateDetails()">
              {{ 'profile-details.button' | translate }}
            </ion-button>
          </div>
        </form>
      </div>
    </ng-template>
  </ion-modal>

  <!-- YouTube Modal -->
  <ion-modal [isOpen]="isYouTubeModalOpen" (didDismiss)="closeModal('youtube')">
    <ng-template>
      <div class="modal-content">
        <h3>YouTube</h3>
        <form [formGroup]="formgroup">
          <ion-item class="bordered">
            <ion-label position="floating" color="accent">YouTube Channel ID</ion-label>
            <ion-input type="text" formControlName="youtube_id"></ion-input>
          </ion-item>
          <div class="ion-text-center">
            <ion-button
                    [disabled]="!formgroup.valid"
                    style="background: var(--accent); border-radius: 10px;"
                    expand="block"
                    color="tertiary"
                    (click)="updateDetails()">
              {{ 'profile-details.button' | translate }}
            </ion-button>
          </div>
        </form>
      </div>
    </ng-template>
  </ion-modal>

  <div *ngIf="selectedTab === 'payments'" style="width: 100%" class="tab-content">
    <ion-item>
      <ion-label>{{ 'Get paid through company' | translate }}</ion-label>
      <ion-checkbox slot="end" [(ngModel)]="getPaidThroughCompany"></ion-checkbox>
    </ion-item>

    <!-- Conditional Form for Adding Company Details -->
    <div *ngIf="getPaidThroughCompany">
      <form [formGroup]="companyFormGroup">
        <ion-list inset lines="none" class="profile-list">
          <ion-item class="form-item">
            <ion-label position="floating">{{ 'CIF / CNP' | translate }}</ion-label>
            <ion-input formControlName="cif"></ion-input>
          </ion-item>

          <ion-item class="form-item">
            <ion-label position="floating">{{ 'Denumire' | translate }}</ion-label>
            <ion-input formControlName="name"></ion-input>
          </ion-item>

          <ion-item class="form-item">
            <ion-label position="floating">{{ 'Reg com' | translate }}</ion-label>
            <ion-input formControlName="reg_com"></ion-input>
          </ion-item>

          <ion-item class="form-item">
            <ion-label position="floating">{{ 'Adresa' | translate }}</ion-label>
            <ion-input formControlName="address"></ion-input>
          </ion-item>

          <ion-item class="form-item">
            <ion-label position="floating">{{ 'Localitate' | translate }}</ion-label>
            <ion-input formControlName="locality"></ion-input>
          </ion-item>

          <ion-item class="form-item">
            <ion-label position="floating">{{ 'Judet' | translate }}</ion-label>
            <ion-input formControlName="county"></ion-input>
          </ion-item>

          <ion-item class="form-item">
            <ion-label position="floating">{{ 'Tara' | translate }}</ion-label>
            <ion-select formControlName="country">
              <ion-select-option value="RO">Romania</ion-select-option>
              <!-- Add more country options -->
            </ion-select>
          </ion-item>

          <ion-item class="form-item">
            <ion-label position="floating">{{ 'IBAN' | translate }}</ion-label>
            <ion-input formControlName="iban"></ion-input>
          </ion-item>

          <ion-item class="form-item">
            <ion-label position="floating">{{ 'Banca' | translate }}</ion-label>
            <ion-input formControlName="bank"></ion-input>
          </ion-item>

          <ion-item class="form-item">
            <ion-label position="floating">{{ 'Email' | translate }}</ion-label>
            <ion-input formControlName="email"></ion-input>
          </ion-item>

          <ion-item class="form-item">
            <ion-label position="floating">{{ 'Persoana Contact' | translate }}</ion-label>
            <ion-input formControlName="contact_person"></ion-input>
          </ion-item>

          <ion-item class="form-item">
            <ion-label position="floating">{{ 'Telefon' | translate }}</ion-label>
            <ion-input formControlName="phone"></ion-input>
          </ion-item>

          <ion-item class="form-item">
            <ion-label>{{ 'Platitor TVA' | translate }}</ion-label>
            <ion-radio-group formControlName="vat_payer">
              <ion-item>
                <ion-label>{{ 'Da' | translate }}</ion-label>
                <ion-radio slot="start" value="yes"></ion-radio>
              </ion-item>
              <ion-item>
                <ion-label>{{ 'Nu' | translate }}</ion-label>
                <ion-radio slot="start" value="no"></ion-radio>
              </ion-item>
            </ion-radio-group>
          </ion-item>
        </ion-list>

        <div class="ion-text-center">
          <ion-button
                  [disabled]="!companyFormGroup.valid"
                  (click)="saveCompanyDetails()"
                  color="primary"
                  expand="block"
          >
            {{ 'Save' | translate }}
          </ion-button>
        </div>
      </form>
    </div>

    <form *ngIf="!getPaidThroughCompany" [formGroup]="formgroup">
      <ion-list inset lines="none" class="profile-list">
        <ion-item class="form-item">
          <ion-label position="floating" color="accent" >{{ 'profile.PIN' | translate }}</ion-label>
          <ion-input formControlName="bank_name"></ion-input>
        </ion-item>
        <ion-item class="form-item">
          <ion-label position="floating" color="accent" >{{ 'profile.bank-name' | translate }}</ion-label>
          <ion-input formControlName="bank_name"></ion-input>
        </ion-item>
        <ion-item class="form-item">
          <ion-label position="floating" color="accent" >{{ 'profile.bank' | translate }}</ion-label>
          <ion-input formControlName="bank_number"></ion-input>
        </ion-item>
        <ion-item *ngIf="formgroup.controls.bank_number.errors  && formgroup.controls.bank_number.touched">
          <p class="error" >{{ 'profile.bank-error' | translate }}</p>
        </ion-item>
      </ion-list>
    </form>

    <div class="ion-text-center" style="display: flex; flex-direction: row">
      <ion-button [disabled]="!formgroup.valid || missing_interest" style="width: 100%; background: var(--accent); border-radius: 10px;" expand="block" color="red"  (click)="updateDetails()">{{ 'profile-details.button' | translate }}</ion-button>
    </div>

  </div>

</ion-content>
<ion-footer>
  <div  class="bottom-tabs" >
    <button (click)="home()">
      <ion-icon name="home" style="font-size: 30px;"></ion-icon>
    </button>
    <button (click)="offers()" style="position: relative">
      <ion-icon name="megaphone" style="font-size: 30px;"></ion-icon>
        <div *ngIf="offers_count > 0" class="badge-notification">{{offers_count}}</div>
    </button>
    <button (click)="archive()">
      <ion-icon name="archive" style="font-size: 30px;"></ion-icon>
    </button>
    <button class="selected-tab">
      <ion-icon name="person-circle" style="font-size: 30px;"></ion-icon>
    </button>
  </div>
</ion-footer>
