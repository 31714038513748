/* src/app/pages/signup/signup.page.scss */
h1 {
  color: var(--text-primary);
}
@media screen and (min-width: 624px) {
  h1 {
    font-size: 38px;
  }
}
.item-input .label-md,
.item-select .label-md,
.item-datetime .label-md {
  color: var(--text-primary);
}
.item-input-has-focus .label-md[stacked],
.input-has-focus .label-md[stacked],
.item-input-has-focus .label-md[floating],
.input-has-focus .label-md[floating] {
  color: var(--text-primary);
}
.list-md .item-input {
  border-bottom: 1px solid var(--text-primary);
}
.label-floating {
  color: var(--text-primary);
}
ion-item {
  border-bottom: 1px solid var(--text-primary) !important;
}
a {
  color: var(--text-primary);
  text-decoration: underline;
}
@media screen and (min-width: 624px) {
  a {
    font-size: 20px;
  }
}
ion-icon.grey,
ion-input,
p {
  color: var(--text-primary) !important;
}
.logo-brand {
  width: 70%;
  display: block;
  margin: 15% auto 0;
}
.logo-brand img {
  width: 100%;
  object-fit: contain;
}
ion-content {
  --background: url("./media/background.jpg") 0 0/100% 100% no-repeat;
}
ion-list {
  --ion-item-background: transparent;
}
ion-list ion-item {
  --ion-item-background: transparent;
}
ion-list ion-item ion-input {
  width: calc(100% - 10px);
}
.content-md {
  background: url("./media/background.jpg");
  background-size: cover;
}
.logo {
  width: 50%;
  display: block;
  margin: 15% auto 0;
}
.item-md {
  background: transparent;
}
.error {
  color: var(--danger) !important;
}
.divider {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  max-width: 700px;
  margin: 0 auto;
}
.striked-text {
  flex: 1;
}
.divider div[flex-grow] {
  flex-grow: 1;
}
.button-md.onboard {
  font-size: 20px;
  font-weight: 100;
  text-transform: none;
  padding: 25px 0;
}
.input-wrapper {
  flex-direction: column;
  margin: 10px 8px 0 0;
  flex: 0 0 85%;
}
.item-inner {
  box-shadow: none !important;
  width: 100%;
}
.list-md .item-input:last-child {
  border-bottom: 1px solid var(--text-primary) !important;
  box-shadow: none !important;
}
.list-md[lines=none] .item {
  border-width: 1px;
}
@media screen and (min-width: 624px) {
  .list-md[lines=none] .item {
    font-size: 18px;
  }
}
@media screen and (min-width: 624px) {
  .text-f-p {
    font-size: 20px;
  }
}
ion-checkbox {
  top: 7px;
  margin: 0 4px;
}
ion-icon.grey {
  margin: 0 5px -3px;
}
/*# sourceMappingURL=signup.page.css.map */
