/* src/app/pages/ig-login/ig-login.page.scss */
.toolbar-content,
.toolbar-title {
  color: #ffffff;
  font-weight: 200;
}
.toolbar-background {
  background: var(--beige);
}
h4 {
  margin: 30px 0 0 !important;
  color: var(--beige);
  font-weight: 100;
}
h5 {
  margin: 3px 0 10px !important;
  font-weight: 300;
}
.m-b-40 {
  margin-bottom: 40px !important;
}
.flex {
  display: flex;
}
.flex ion-input {
  display: inline-block;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  flex: 3;
  margin-right: 5px;
  padding: 6px 15px !important;
}
.flex button {
  flex: 1;
  height: 4.2rem;
}
.code_verification {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
.code_verification #cpyBtn {
  text-align: left;
  background-color: transparent;
  font-weight: 300 !important;
}
.code_verification #copyTarget {
  border: 1px solid #d8d8d8;
  padding: 8px 0;
  text-align: center;
  border-radius: 5px;
  color: var(--beige);
  background-color: transparent;
}
.account-status {
  text-align: center;
  margin: 10px 0;
  color: #d00000;
}
.post_image {
  text-align: center;
}
.post_image img {
  max-width: 60%;
}
button {
  font-weight: 100 !important;
  margin: 0 auto !important;
  text-transform: none !important;
}
button ion-icon {
  margin-top: -1px;
}
img.icon {
  width: 24px;
  margin-right: 10px;
  filter: invert(100%);
}
ol {
  padding-left: 20px;
}
ol li {
  margin-top: 12px;
}
ion-footer {
  background: none;
}
/*# sourceMappingURL=ig-login.page.css.map */
