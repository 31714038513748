/* src/app/pages/profile-details/profile-details.page.scss */
.input-number input::-webkit-outer-spin-button,
.input-number input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.input-number input[type=number] {
  -moz-appearance: textfield;
}
ion-label {
  margin: 13px 8px 13px 0;
  display: block;
  color: var(--text-primary);
}
.top-section {
  height: 120px;
  margin-top: -20px;
  margin-bottom: 50px;
  display: block;
}
ion-buttons {
  position: absolute;
}
.delete-btn {
  width: 50%;
}
.checkbox-md {
  margin: 9px 9px 9px 4px !important;
}
.ion-md-log-out {
  color: #fff;
  opacity: 1 !important;
  height: auto !important;
  width: auto !important;
}
.visual {
  width: 100%;
  height: 80px;
  background-color: var(--accent);
  z-index: 1;
}
ion-footer {
  height: calc(70px + env(safe-area-inset-bottom));
  background-color: var(--primary);
}
.spacer {
  width: 100%;
  position: absolute;
  height: 60px;
  bottom: 0;
  z-index: 1;
}
.image {
  height: 100px;
  width: 100px;
  z-index: 2;
  margin-top: -50px;
  margin-bottom: 100px;
  border-radius: 10%;
  overflow: hidden;
  border: solid 2px #fff;
  display: inline-block;
}
.interests .icon-set {
  display: flex;
}
.interests .icon-set div {
  flex: 1;
  position: relative;
}
ion-icon[name=log-out-outline] {
  color: #ffffff;
  font-size: 1.5rem;
}
.icon {
  height: 48px;
  width: 48px;
  background-size: cover;
  display: inline-block;
  opacity: 0.4;
}
.icon-art {
  background-image: url("./media/art.svg");
}
.icon-beauty {
  background-image: url("./media/beauty.svg");
}
.icon-design {
  background-image: url("./media/design.svg");
}
.icon-fashion {
  background-image: url("./media/fashion.svg");
}
.icon-foodies {
  background-image: url("./media/foodies.svg");
}
.icon-lifestyle {
  background-image: url("./media/lifestyle.svg");
}
.icon-mommies {
  background-image: url("./media/mommies.svg");
}
.icon-music {
  background-image: url("./media/music.svg");
}
.icon-sports {
  background-image: url("./media/fitness.svg");
}
.icon-travel {
  background-image: url("./media/travel.svg");
}
.icon-dog {
  background-image: url("./media/dog.png");
}
.icon-cat {
  background-image: url("./media/cat.png");
}
.icon-rabbit {
  background-image: url("./media/rabbit.png");
}
.icon-hamster {
  background-image: url("./media/hamster.png");
}
.icon-rat {
  background-image: url("./media/rat.png");
}
.icon-pig {
  background-image: url("./media/pig.png");
}
.icon-mouse {
  background-image: url("./media/mouse.png");
}
.icon-bird {
  background-image: url("./media/bird.png");
}
.icon-ferret {
  background-image: url("./media/ferret.png");
}
.icon-aqua {
  background-image: url("./media/fish.png");
}
.icon-set span {
  display: block;
  font-size: 12px;
  margin-bottom: 30px;
}
.icon-selected {
  opacity: 1;
}
.icon-selected:after {
  opacity: 0.9;
}
.item-input .label-md,
.item-select .label-md,
.item-datetime .label-md {
  color: var(--text-primary);
}
.error {
  margin: 0 0 2px;
  font-size: 14px;
  color: #FF0706;
}
.item {
  padding-left: 0;
  min-height: 66px;
}
.bordered {
  border-width: 1px !important;
  border-bottom: solid 1px #a5a5a5;
}
form {
  margin-top: -30px;
}
.item-md.item-input.ng-valid.item-input-has-value:not(.input-has-focus):not(.item-input-has-focus) .item-inner,
.item-md.item-input.ng-valid.input-has-value:not(.input-has-focus):not(.item-input-has-focus) .item-inner {
  box-shadow: none;
}
@media screen and (min-width: 624px) {
  .profile-list {
    max-width: 80%;
    margin: 20px auto 0 !important;
  }
  .item-md {
    font-size: 18px;
  }
  .image {
    width: 100px;
    height: 100px;
  }
}
.slide-zoom {
  text-align: center;
  display: block;
  width: 100%;
}
.tab-main {
  left: 0;
  top: 0;
  position: absolute;
  z-index: 0;
  display: block;
  width: 100%;
  height: 100%;
}
.tabbar {
  z-index: 10;
  display: flex;
  width: 100%;
  position: sticky;
  top: 0;
  background-color: #fff;
}
.tabbar::after {
  left: 0;
  bottom: -5px;
  background-position: left 0 top -2px;
  position: absolute;
  width: 100%;
  height: 5px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAHBAMAAADzDtBxAAAAD1BMVEUAAAAAAAAAAAAAAAAAAABPDueNAAAABXRSTlMUCS0gBIh/TXEAAAAaSURBVAjXYxCEAgY4UIICBmMogMsgFLtAAQCNSwXZKOdPxgAAAABJRU5ErkJggg==);
  background-repeat: repeat-x;
  content: "";
}
.tab-button {
  margin: 0;
  text-align: center;
  border-radius: 0;
  position: relative;
  z-index: 0;
  display: flex;
  overflow: hidden;
  flex: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;
  border: 0;
  text-decoration: none;
  background: 0 0;
  cursor: pointer;
  color: #d9d9d9;
  height: 56px;
}
.tab-button .tab-button-text {
  text-transform: uppercase;
  font-weight: 800;
}
.tab-button[aria-selected=true] {
  position: relative;
  color: #000;
}
.tab-button[aria-selected=true]:after {
  width: 35%;
  border-color: var(--primary);
  border: none;
  border-bottom: solid 4px var(--primary);
  position: absolute;
  content: " ";
  bottom: 0;
}
.tabbar {
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
}
.tab-button {
  flex: 1;
  text-align: center;
  padding: 10px 0;
  font-weight: bold;
  color: var(--text-muted);
  cursor: pointer;
}
.tab-button.active-tab {
  color: #000;
  border-bottom: 3px solid var(--ion-color-primary);
}
.tab-highlight {
  height: 3px;
  background: var(--ion-color-primary);
}
.tab-content {
  margin-top: 16px;
}
.social-connect {
  background: var(--primary);
  padding: 16px;
  border-radius: 10px;
  border: 2px solid var(--accent);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0;
}
.connect-btn {
  color: black;
  font-size: 14px;
  font-weight: 800;
  border: 0;
}
.modal-content {
  padding: 20px;
  text-align: center;
}
.form-item {
  border: 2px solid black;
  border-radius: 5px;
  margin: 10px 0;
}
.modal-content h3 {
  font-size: 20px;
  margin-bottom: 20px;
}
/*# sourceMappingURL=profile-details.page.css.map */
