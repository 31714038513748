import { Component, OnInit } from '@angular/core';
import {IonicModule, NavController} from '@ionic/angular';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import { DomSanitizer} from '@angular/platform-browser';
import { environment } from 'custom/environment';
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {TncFramePageRoutingModule} from "./tnc-frame-routing.module";

@Component({
  selector: 'app-tnc-frame',
  templateUrl: './tnc-frame.page.html',
  styleUrls: ['./tnc-frame.page.scss'],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TncFramePageRoutingModule,
    TranslateModule
  ],
})
export class TncFramePage implements OnInit {

  lang: string;
  tncLink: any;
  tncFull: string = environment.tncHtml
  constructor(private domSanitizer : DomSanitizer, public navCtrl: NavController,private translate: TranslateService) {
    this.lang = this.translate.currentLang?this.translate.currentLang:this.translate.getDefaultLang();
    this.tncLink = this.domSanitizer.bypassSecurityTrustResourceUrl("https://" + (this.lang=="en"?"www":this.lang) + ".ldrsgroup.com/application-terms-conditions/");
  }

  ngOnInit() {
  }

  goBack() {
    this.navCtrl.navigateBack('signup');
  }

}
