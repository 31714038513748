import { Component, OnInit } from '@angular/core';
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {IonicModule} from "@ionic/angular";
import {PendingApprovalPageRoutingModule} from "./pending-approval-routing.module";
import {TranslateModule} from "@ngx-translate/core";



@Component({
  selector: 'app-pending-approval',
  templateUrl: './pending-approval.page.html',
  styleUrls: ['./pending-approval.page.scss'],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PendingApprovalPageRoutingModule,
    TranslateModule
  ],
})
export class PendingApprovalPage implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
