<ion-content class="ion-padding" >
  <ion-icon class="globe-icon" name="globe-outline"></ion-icon>
  <ion-select (ionChange)="changeLanguage()"
              okText="{{ 'ok' | translate }}"
              cancelText="{{ 'cancel' | translate }}"
              [interfaceOptions]="languageSelectOptions"
              [(ngModel)]="language"
              selectedText="{{language}}"
              class="change-language">
    <ion-select-option value="en">English</ion-select-option>
    <ion-select-option value="es">Español</ion-select-option>
    <ion-select-option value="de">Deutsch</ion-select-option>
    <ion-select-option value="ru">Русский</ion-select-option>
    <ion-select-option value="ku">کورد</ion-select-option>
    <ion-select-option value="ar">اَلْعَرَبِيَّةُ</ion-select-option>
    <ion-select-option value="he">עברית</ion-select-option>
  </ion-select>

  <div class="divider">
    <div class="logo-brand">
      <img src="../../../custom/logo.png" alt="logo">
    </div>
    <h1 class="ion-text-center">{{ 'signup.signup' | translate }}</h1>
    <form [formGroup]="formgroup">
      <ion-list lines="none">
        <ion-item>
          <ion-label position="floating">{{ 'email_address' | translate }}</ion-label>
          <ion-input type="text" formControlName="email"></ion-input>
        </ion-item>
        <p class="error" *ngIf="formgroup.controls['email'].errors && formgroup.controls['email'].touched">{{ 'signup.email_error' | translate }}</p>
        <ion-item>
          <div class="item-inner flex">
            <div class="input-wrapper flex">
              <ion-label position="floating">{{'password' | translate}}</ion-label>
              <ion-input formControlName="password" type="{{type}}" require></ion-input>
            </div>
            <ion-button (click)="showPassword()" slot="end" fill="clear" type="button" class="show-password">
              <ion-icon name="eye" *ngIf="!passwordVisible"></ion-icon>
              <ion-icon name="eye-off" *ngIf="passwordVisible"></ion-icon>
            </ion-button>
          </div>
        </ion-item>
        <p class="error" *ngIf="formgroup.controls['password'].errors && formgroup.controls['password'].touched">{{ 'signup.password_error' | translate }}</p>
        <ion-item>
          <ion-label position="floating">{{ 'password_verify' | translate }}</ion-label>
          <ion-input [type]="type" formControlName="password_verify"></ion-input>
        </ion-item>
        <p class="error" *ngIf="formgroup.controls['password_verify'].errors && formgroup.controls['password'].touched">{{ 'signup.password_verify_error' | translate }}</p>
      </ion-list>
      <ion-button [disabled]="!formgroup.valid || !tnc" (click)="signupWithEmail()" expand="block" class="onboard ion-margin-top">{{ 'signup.button' | translate }}</ion-button>
    </form>
    <p ion-text color="primary" class="ion-text-center text-f-p">
      <ion-checkbox [(ngModel)]="tnc"></ion-checkbox>
      <ion-label>{{'signup.disclaimer' | translate }} <a (click)="navCtrl.navigateForward('tnc')">{{ 'signup.tnc' | translate }}</a></ion-label>
    </p>
    <p ion-text color="primary"  class="ion-text-center text-f-p">
      {{ 'signup.signin-disclaimer' | translate }}
      <a (click)="navCtrl.navigateRoot('signin')">
        {{ 'signup.signin' | translate }}
      </a>
    </p>
    <p ion-text color="light" class="ion-text-center">
      <ion-icon class="grey" name="mail"></ion-icon>
      <a href="mailto:{{supportEmail}}">{{ supportEmail }}</a>
    </p>
  </div>
</ion-content>
