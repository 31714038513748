import * as i0 from '@angular/core';
import { Injectable, Inject } from '@angular/core';
import * as i1 from '@angular/common/http';
class TranslateHttpLoader {
  http;
  prefix;
  suffix;
  constructor(http, prefix = "/assets/i18n/", suffix = ".json") {
    this.http = http;
    this.prefix = prefix;
    this.suffix = suffix;
  }
  /**
   * Gets the translations from the server
   */
  getTranslation(lang) {
    return this.http.get(`${this.prefix}${lang}${this.suffix}`);
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.0.5",
    ngImport: i0,
    type: TranslateHttpLoader,
    deps: [{
      token: i1.HttpClient
    }, {
      token: String
    }, {
      token: String
    }],
    target: i0.ɵɵFactoryTarget.Injectable
  });
  static ɵprov = i0.ɵɵngDeclareInjectable({
    minVersion: "12.0.0",
    version: "19.0.5",
    ngImport: i0,
    type: TranslateHttpLoader
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.0.5",
  ngImport: i0,
  type: TranslateHttpLoader,
  decorators: [{
    type: Injectable
  }],
  ctorParameters: () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [String]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [String]
    }]
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { TranslateHttpLoader };
