<ion-header>

  <ion-toolbar>
    <ion-icon (click)="goBack()" name="arrow-back"></ion-icon>
    <ion-title>{{ 'tnc' | translate }}</ion-title>
  </ion-toolbar>

</ion-header>


<ion-content class="ion-padding"  no-bounce no-margin>

  <div [innerHtml]="tncFull"></div>
</ion-content>
