import { Component, OnInit } from '@angular/core';
import {IonicModule, NavController} from '@ionic/angular';
import {
    ApiService, BroadcastServiceProvider
} from "../../services/providers.service";
import {CampaignInterface} from "../../../interfaces/CampaignInterface";
import {UserService} from "../../services/user.service";
import { EventsService } from '../../services/events.service'
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {CampaignOffersPageRoutingModule} from "./campaign-offers-routing.module";


@Component({
  selector: 'app-campaign-offers',
  templateUrl: './campaign-offers.page.html',
  styleUrls: ['./campaign-offers.page.scss'],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CampaignOffersPageRoutingModule,
    TranslateModule
  ],
})
export class CampaignOffersPage implements OnInit {

    type: string;
    title: string;
    offers_count: string;
    message: string= "Looking for the perfect match, thanks for your patience!";
    campaigns: CampaignInterface[];
    userService: UserService;
    logo: string = '<ion-icon name="contact"></ion-icon>';

    constructor(public navCtrl: NavController,
              public events: EventsService,
              private apiService: ApiService,
              public _userService: UserService,
              private route: ActivatedRoute,
              private broadcastService: BroadcastServiceProvider,
              private translate:TranslateService) {
      this.userService = _userService;
      this.type = this.route.snapshot.params['type'];


      // printGroup("Logo",_userService.getProfilePicture(),true);
      if (this.userService.getProfilePicture() != 'contact') {
          // this.logo = "<ion-avatar [src]="+this.userService.getProfilePicture() + "></ion-avatar>-->";
          this.logo = "<img src=" + this.userService.getProfilePicture() + ">";
      }
      this.events.subscribe('APP_ROOT:REFRESH_TABS',()=>{
          this.doRefresh(0);
      })
      events.subscribe('change:type', ( ev )=> {
          this.title = this.translate.instant('campaign-offers.offers-tab.title');
          this.message = this.translate.instant('campaign-offers.offers-tab.message');
      });
    }

    ngOnInit() {
    }
    ionViewDidEnter () {
    if ( ! this.type){
      this.offers();
    } else {
    this.doRefresh(0);
    }
    }

    doRefresh(refresher){
      this.apiService.getCampaignOffers(this.type).then(
          (su: any) => {
              this.campaigns = su.offers;
              this.offers_count = su.offers_count
              switch(this.type) {
                case 'offers':
                    this.title = this.translate.instant('campaign-offers.offers-tab.title');
                    this.message = this.translate.instant('campaign-offers.offers-tab.message');
                    window["app_ga"]('send', 'pageview', "campaigns_offers");
                    break;
                case 'pending':
                    this.title = this.translate.instant('campaign-offers.pending-tab.title');
                    this.message = this.translate.instant('campaign-offers.pending-tab.message');
                    window["app_ga"]('send', 'pageview', "campaigns_pending");
                    break;
                case 'approved':
                    this.title = this.translate.instant('campaign-offers.approved-tab.title');
                    this.message = this.translate.instant('campaign-offers.approved-tab.message');
                    window["app_ga"]('send', 'pageview', "campaigns_approved");
                    break;
                case 'old':
                    this.title = this.translate.instant('campaign-offers.archive-tab.title');
                    this.message = this.translate.instant('campaign-offers.archive-tab.message');
                    window["app_ga"]('send', 'pageview', "campaigns_archive");
                    break;
              }
              if(refresher != 0)
                  refresher.target.complete();
          },
          er => {
              this.broadcastService.publishError(er);
              if(refresher != 0)
                refresher.target.complete();
            },
      );
    }

    open(params?) {
      if (params.client_match == '3'){
          return false
      }
      this.navCtrl.navigateForward('/campaign/'+params.id)
      return true
    }

    archive() {
      this.type = 'old';
      this.doRefresh(0)
    }

    offers(){
      this.type = 'offers';
      this.doRefresh(0)
    }
    approved(){
    this.type = 'approved';
    this.doRefresh(0)
    }
    pending() {
    this.type = 'pending';
    this.doRefresh(0);
    }


    profile(){
      this.navCtrl.navigateRoot('/profile/'+this.offers_count)
    }

    home() {
        this.navCtrl.navigateRoot('/homepage/'+this.offers_count)
    }
}
