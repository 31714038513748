<!--
  Generated template for the IgLoginPage page.

  See http://ionicframework.com/docs/components/#navigation for more info on
  Ionic pages and navigation.
-->
<ion-header>
  <ion-toolbar>
    <ion-title class="ion-text-center">{{ 'ig-login.title' | translate}}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <h5 *ngIf="!has_user" class="ion-text-center">{{ 'ig-login.add-account' | translate}}</h5>
  <h5 *ngIf="has_user" class="ion-text-center">{{ 'ig-login.verify-account' | translate}}</h5>
  <div class="flex"  *ngIf="!has_user">
    <ion-input type="text" [(ngModel)]="username" placeholder="{{ 'ig-login.username' | translate}}"></ion-input>
    <ion-button [disabled]="!username" (click)="getVerificationCode()">{{ 'ig-login.next' | translate}}</ion-button>
  </div>
  <div class="account-status">
    {{account_status}}
  </div>
  <div *ngIf="has_user">
    <div class="post_image">
      <img *ngIf="recent_post_image" src="{{recent_post_image}}">
    </div>
    <div style="margin-bottom: 8px; font-size: 10px" class="ion-text-center">
      <a (click)="changeUser()">{{ 'ig-login.change-user' | translate}}</a>
    </div>
    <b class="ion-text-center" style="margin: 5px">{{ 'ig-login.steps.step-0' | translate}}</b>
    <ol>
      <li>{{ 'ig-login.steps.step-1' | translate}}</li>
      <div *ngIf="verification_code" class="code_verification">
        <button id="cpyBtn" data-clipboard-target="#copyTarget">
          {{ 'ig-login.tap-copy' | translate}}
        </button>
        <input type="text" readonly="readonly" id="copyTarget" [value]="verification_code">
      </div>
      <li>{{ 'ig-login.steps.step-2' | translate}}</li>
      <li>{{ 'ig-login.steps.step-3' | translate}}</li>
    </ol>
    <div class="ion-custom-item" style="margin-top: 15px;">
      <ion-button *ngIf="verification_code" (click)="verifyIg()" color="primary" expand="block" class="onboard">{{ 'ig-login.verify' | translate}}</ion-button>
    </div>
  </div>
</ion-content>

