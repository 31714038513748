import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import {CommonModule} from "@angular/common";

@Component({
    selector: 'app-statistics',
    templateUrl: './statistics.component.html',
    styleUrls: ['./statistics.component.scss'],
  imports: [
    CommonModule
  ]
})
export class StatisticsComponent implements OnInit {
    userStats: any;
    audienceDemographics: any;
    engagementMetrics: any;

    constructor(private apiService: ApiService) {}

    ngOnInit(): void {
        const influencerId = '12'; // Replace with actual ID
        this.loadUserStats(influencerId);
        this.loadAudienceDemographics(influencerId);
        this.loadEngagementMetrics(influencerId);
    }

    loadUserStats(influencerId: string): void {
        this.apiService.getUserStats(influencerId).subscribe((data) => {
            this.userStats = data;
            console.log('User Stats:', data);
        });
    }

    loadAudienceDemographics(influencerId: string): void {
        this.apiService.getAudienceDemographics(influencerId).subscribe((data) => {
            this.audienceDemographics = data;
            console.log('Audience Demographics:', data);
        });
    }

    loadEngagementMetrics(influencerId: string): void {
        this.apiService.getEngagementMetrics(influencerId).subscribe((data) => {
            this.engagementMetrics = data;
            console.log('Engagement Metrics:', data);
        });
    }
}
