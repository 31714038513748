<ion-header>
	<div style="text-align: center; display: flex; justify-content: space-between">
		<div style="margin: 10px 0 10px 10px;">
			<h3 style="color: black; font-weight: 800; font-size: 15px">
				{{title}}
			</h3>
		</div>

		<div>
			<img style="height: 50px; margin: 10px 0 10px 0;" src="../../../custom/logo_svg.png" alt="logo">
			<img style="height: 50px; margin: 10px 5px 10px 0;" src="../../../custom/logo_text.png" alt="logo">
		</div>
	</div>
</ion-header>

<ion-content>

	<ion-refresher slot="fixed" (ionRefresh)="doRefresh($event);">
		<ion-refresher-content pullingText="Pull to refresh"
							   pullingIcon="arrow-dropdown"
							   refreshingSpinner="circles"
							   refreshingText="..fetching">

		</ion-refresher-content>
	</ion-refresher>
  <div class="tabbar show-tabbar" role="tablist" >
	<a class="tab-button has-title has-title-only" (click)="offers()" [attr.aria-selected]="type==='offers'"><span class="tab-button-text">{{ 'campaign-tabs.offers' | translate }}</span><div class="button-effect"></div></a>
	<a class="tab-button has-title has-title-only" (click)="pending()" [attr.aria-selected]="type==='pending'"><span class="tab-button-text">{{ 'campaign-tabs.pending' | translate }}</span><div class="button-effect" ></div></a>
	<a class="tab-button has-title has-title-only" (click)="approved()" [attr.aria-selected]="type==='approved'" ><span class="tab-button-text">{{ 'campaign-tabs.approved' | translate }}</span><div class="button-effect" ></div></a>
	<div class="tab-highlight"></div>
  </div>


	<h3 *ngIf="campaigns?.length == 0" class="nolist-title ion-text-center">{{message}}</h3>

	<ion-list *ngIf="campaigns?.length > 0" lines=“none”>
		<div style="width: 100%" class="list-campaigns" *ngFor="let campaign of campaigns" (click)="open(campaign)" lines="none">
			<div class="floating-card">
				<ion-label class="campaign-title">
					<h3 style="color: black; font-weight: 800; font-size: 12px;
						  border-left: 5px solid;
						  border-image: linear-gradient(to top, black, white) 1; padding-left: 5px">
						{{campaign.name}}
					</h3>
				</ion-label>

				<div class="image-container">
					<img class="logo-img" [src]="campaign.images.logo">
					<div style="overflow: hidden">
						<img class="cover-img" [src]="campaign.images.cover" alt="cover" />
					</div>
				</div>

				<div style="display: flex; justify-content: space-between; padding: 5px 15px 5px 15px">
					<div *ngIf="campaign.platforms == 'instagram'" style="display: flex; flex-direction: row; align-items: center; margin-top: -3px; border-right: 1px solid lightgray; width: 50%">
						<ion-icon name="logo-instagram" style="font-size: 25px; margin-right: 5px;color: #D62976FF;"></ion-icon>
						<span style="font-size: 15px; font-weight: 800;
								   background: linear-gradient(45deg, #feda75, #fa7e1e, #d62976, #962fbf, #4f5bd5);
								   -webkit-background-clip: text;
								   color: transparent;">
						  Instagram
					  	</span>
					</div>

					<div *ngIf="campaign.platforms == 'tiktok'" style="display: flex; flex-direction: row; align-items: center; margin-top: -3px; border-right: 1px solid lightgray; width: 50%">
						<!-- TikTok Icon with solid color matching the brand -->
						<ion-icon name="logo-tiktok" style="font-size: 25px; margin-right: 5px; color: #a61028;"></ion-icon>
						<!-- TikTok Text with gradient -->
						<span style="font-size: 15px; font-weight: 800;
							background: linear-gradient(45deg, #25F4EE, #FE2C55, #000000);
							-webkit-background-clip: text;
							color: transparent;">
						  TikTok
					   	</span>
					</div>

					<div *ngIf="campaign.platforms == 'youtube'" style="display: flex; flex-direction: row; align-items: center; margin-top: -3px; border-right: 1px solid lightgray; width: 50%">
						<!-- YouTube Icon with solid red color -->
						<ion-icon name="logo-youtube" style="font-size: 25px; margin-right: 5px; color: #FF0000;"></ion-icon>

						<!-- YouTube Text with gradient in red shades -->
						<span style="font-size: 15px; font-weight: 800;
									background: linear-gradient(45deg, #FF0000, #CC0000, #990000);
									-webkit-background-clip: text;
									color: transparent;">
						  YouTube
   						</span>
					</div>

<!--					<div style="display: flex; flex-direction: row">-->
<!--						<ion-icon name="cash-outline" style="font-size: 25px; color: #3e9c35; margin-right: 5px; transform: rotate(-15deg)"></ion-icon>-->
<!--						<ion-label>-->
<!--							<p style="font-size: 15px; font-weight: 800; color: #3e9c35;" >-->
<!--								<span>{{ campaign.amount + " RON"}}</span>-->
<!--							</p>-->
<!--						</ion-label>-->
<!--					</div>-->
				</div>

				<div>
					<ion-badge *ngIf="campaign.client_match == 3" class="post-declined no-match" >{{ 'campaign.status.tag-no-match' | translate }}</ion-badge>
					<p *ngIf="campaign?.agent == false && campaign.custom_offer == false && campaign.client_match != 3 || campaign?.agent == false && campaign.custom_offer == true && campaign.reward != 'Volunteering' && campaign.client_match != 3">{{campaign.reward}} <span *ngIf="campaign.leader_match == 0"> </span></p>
					<p *ngIf="campaign.custom_offer == true && campaign.reward == 'Volunteering'">{{ 'campaign.brief.send_offer' | translate }} <span *ngIf="campaign.leader_match == 0"></span></p>
				</div>
			</div>
		</div>
	</ion-list>
</ion-content>

<ion-footer>
	<div class="bottom-tabs" >
		<button (click)="home()" >
			<ion-icon name="home" style="font-size: 30px;"></ion-icon>
		</button>
		<button [class.selected-tab]="this.type != 'old'"  style="position:relative" (click)="offers()">
			<ion-icon name="megaphone" style="font-size: 30px;"></ion-icon>
			<div *ngIf="offers_count" class="badge-notification">{{offers_count}}</div>
		</button>
		<button [class.selected-tab]="this.type == 'old'" (click)="archive()">
			<ion-icon name="archive" style="font-size: 30px;"></ion-icon>
		</button>
		<button (click)="profile()">
			<ion-icon name="person-circle" style="font-size: 30px;"></ion-icon>
		</button>
	</div>
</ion-footer>
