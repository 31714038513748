/* src/app/pages/homepage/homepage.page.scss */
.floating-card-homepage {
  position: relative;
  margin: 10px 10px 10px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  background: var(--accent);
  border-radius: 10px;
  margin: 10px 10px 20px 10px;
  gap: 20px;
  padding: 10px;
}
.social-connect-homepage {
  background: var(--primary);
  padding: 16px;
  border-radius: 10px;
  border: 2px solid var(--accent);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0;
  width: 100%;
}
.connect-btn-homepage {
  color: black;
  font-size: 14px;
  font-weight: 800;
  border: 0;
}
.stat-card {
  background:
    linear-gradient(
      135deg,
      #ffffff,
      #f9f9f9);
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  padding: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.4s ease-in-out;
  position: relative;
}
.stat-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  background:
    linear-gradient(
      135deg,
      #ffffff,
      #f0f4f8);
}
.stat-card::before,
.stat-card::after {
  content: "";
  position: absolute;
  border-radius: 50%;
  opacity: 0.2;
  z-index: 0;
  transition: all 0.4s ease-in-out;
}
.stat-card::before {
  width: 150px;
  height: 150px;
  background:
    radial-gradient(
      circle,
      rgba(0, 123, 255, 0.4),
      transparent);
  top: -30px;
  left: -30px;
}
.stat-card::after {
  width: 100px;
  height: 100px;
  background:
    radial-gradient(
      circle,
      rgba(255, 193, 7, 0.4),
      transparent);
  bottom: -20px;
  right: -20px;
}
.stat-card:hover::before {
  transform: translate(-10px, -10px);
  opacity: 0.3;
}
.stat-card:hover::after {
  transform: translate(10px, 10px);
  opacity: 0.3;
}
.card-header {
  padding: 5px 10px;
  display: flex;
  gap: 12px;
  margin-bottom: 20px;
  z-index: 1;
}
.card-header h2 {
  font-size: 22px;
  font-weight: bold;
  color: #333;
  margin: 0;
  z-index: 1;
}
.card-header ion-icon {
  font-size: 28px;
  z-index: 1;
}
.card-content {
  text-align: left;
  width: 100%;
  z-index: 1;
}
.card-content p {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 16px;
  margin: 10px 0;
  color: var(--accent);
}
.card-content ion-icon {
  font-size: 20px;
  color: #000000;
}
ion-button {
  margin-top: 15px;
}
/*# sourceMappingURL=homepage.page.css.map */
