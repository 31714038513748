import { Component, OnInit } from '@angular/core';
import {ApiService, StorageService} from "../../services/providers.service";
import {NavController, ToastController, AlertController, IonicModule} from '@ionic/angular';
import * as ClipboardJS from 'clipboard/dist/clipboard.min.js';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {IgLoginPageRoutingModule} from "./ig-login-routing.module";


@Component({
  selector: 'app-ig-login',
  templateUrl: './ig-login.page.html',
  styleUrls: ['./ig-login.page.scss'],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    IgLoginPageRoutingModule,
    TranslateModule
  ],
})
export class IgLoginPage implements OnInit {

  clipboard;
  leader_id;
  recent_post_image = null;
  verification_code = null;
  account_status = null;
  has_user = false;
  username;
  constructor(
    public navCtrl: NavController,
    public router: Router,
    public route: ActivatedRoute,
    private apiService: ApiService,
    public toastCtrl: ToastController,
    private alertCtrl:AlertController,
    private storage: StorageService,
    private translate: TranslateService
) {
    this.clipboard = new ClipboardJS('#cpyBtn');
    this.clipboard.on('success', () => this.showMsg(toastCtrl));
    this.route.paramMap.subscribe(params => {
      let st = this.router.getCurrentNavigation().extras.state;
      if (typeof st !== "undefined" && "leader_id" in st) {
        this.leader_id = st.leader_id;
      } else {
        this.storage.get('leader_id').then(data => {
          if (data){
              this.leader_id = data;
          } else {
              this.navCtrl.navigateRoot('/signup');
          }
      });
      }
    });
  }


async showMsg(toastCtrl: ToastController) {
    let toast = await toastCtrl.create({
       message:  this.translate.instant('ig-login.code-copied'),
       duration: 3000,
       position: 'bottom'
   });
   toast.present();
}

/*
IgLogin(){
    window["app_ga"]('send', 'event', "IG Connect", "Started");
    let redirect_link = Settings.INSTAGRAM_REDIRECT_URL + "?id="+this.leader_id;
    window.location.href = "https://api.instagram.com/oauth/authorize/?client_id=" + Settings.INSTAGRAM_APP_ID + "&redirect_uri=" +redirect_link+ "&response_type=token";
}
*/

getVerificationCode(){
    let data = {
        username: this.username,
        leader_id: this.leader_id
    };

    this.apiService.getIgCode(data).then(
        (success: any) => {
            this.recent_post_image = success.data.post_image;
            this.verification_code = success.data.verification_code;
            this.has_user = true;
            this.account_status = null;
            this.storage.store('igVerification', {verification_code: this.verification_code, post_image: this.recent_post_image, account_status: this.account_status, username: this.username, leader_id: this.leader_id, has_user: this.has_user});
            window["app_ga"]('send', 'pageview', "ob_ig_valid_user");
        }, error2 => {
            this.recent_post_image = null;
            this.verification_code = null;
            this.account_status = this.translate.instant('ig-login.errors.' + error2.error.error);
            switch (error2.error) {
                case 'PRIVATE_ACCOUNT':
                    window["app_ga"]('send', 'pageview', "ob_ig_private");
                    break;
                case 'USER_NOT_EXIST':
                    window["app_ga"]('send', 'pageview', "ob_ig_not_exist");
                    break;
                case 'GENERAL_ERROR':
                    window["app_ga"]('send', 'pageview', "ob_ig_error");
                    break;
                case 'FOLLOWERS_LIMIT':
                    window["app_ga"]('send', 'pageview', "ob_ig_insufficient");
                    break;
                case 'IG_ACCOUNT_EXIST':
                    window["app_ga"]('send', 'pageview', "ob_ig_exists");
                    break;
                case 'NO_POSTS':
                    window["app_ga"]('send', 'pageview', "ob_ig_no_posts");
                    break;
            }
        }
    )
}

changeUser(){
      this.has_user = false;
      this.username = null;
      this.storage.remove('igVerification');
}

verifyIg(){
    let data = {
        leader_id: this.leader_id
    };
    this.apiService.verifyIg(data).then(
        (success: any) => {
            window["app_ga"]('send', 'pageview', "ob_completed");
            this.storage.get('language').then( lang => {
                let language = lang;
                this.storage.clear().then(su => {
                    this.storage.pStore('social_network', 'email').then(su => {
                        this.storage.pStore('access_token', success.token).then(su2 => {
                            this.storage.pStore('language', language).then(su3 => {
                                this.apiService.login().then( (success: any) => {
                                    this.apiService.setLeader(success.message);
                                    this.navCtrl.navigateRoot('/pending-approval');
                                });
                            })}
                        ).catch(erCatch2 => {})
                    });
                })
            })
        }, async error2 => {
            let alert = await this.alertCtrl.create({
                header:  this.translate.instant('ig-login.not-verified.title'),
                subHeader: this.translate.instant('ig-login.not-verified.message'),
                buttons: [
                    { text:  this.translate.instant('ig-login.not-verified.confirm') , role: 'cancel'},
                ],
                mode: 'ios'
            });
            await alert.present();
        }
    );

}

  ngOnInit() {
    this.storage.get('igVerification').then(
      success => {
          if (success){
              this.verification_code = success.verification_code;
              this.recent_post_image = success.post_image;
              this.account_status    = success.account_status;
              this.username          = success.username;
              this.has_user          = success.has_user;
          }
      });

  }

}
