/* src/app/pages/tnc-frame/tnc-frame.page.scss */
page-tnc-frame iframe {
  border: solid 1px rgba(0, 0, 0, 0);
}
page-tnc-frame .bar-button-default-md,
page-tnc-frame .bar-button-clear-md-default,
page-tnc-frame .bar-button-md-default {
  color: #fff;
}
/*# sourceMappingURL=tnc-frame.page.css.map */
