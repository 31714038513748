<ion-header>
  <div style="text-align: center; display: flex; justify-content: space-between;">
    <div style="margin: 10px 0 10px 10px; display: flex; justify-content: center; flex-direction: column">
      <ion-icon name="notifications" style="font-size: 30px; color: black"></ion-icon>
    </div>

    <div>
      <img style="height: 50px; margin: 10px 0 10px 0;" src="../../../custom/logo_svg.png" alt="logo">
      <img style="height: 50px; margin: 10px 5px 10px 0;" src="../../../custom/logo_text.png" alt="logo">
    </div>
  </div>
</ion-header>

<ion-content>
  <app-statistics></app-statistics>
  <div style="text-align: center; margin-bottom: 20px;">
    <h1 style="font-weight: 800; font-size: 15px; color: #222;">Welcome back, <span style="color: #ffffff; background: black; border-radius: 5px; padding: 4px;">influencer</span></h1>
  </div>

  <div class="stats-grid">
    <div style="display: flex; flex-direction: row; justify-content: space-between">
      <h2 style="font-size: 20px; font-weight: 800; color: white; text-align: left; padding-left: 10px"> Connections </h2>
      <button style="border-radius: 10px; border: 2px solid white; background: none; padding: 0 10px; color: white">Refresh</button>
    </div>

    <div class="stat-card">
      <p style="color: var(--accent); font-size: 13px; text-align: center">You might want to connect these accounts: </p>

      <!-- TikTok Connect Button -->
      <div class="social-connect-homepage">
        <div style="display: flex; flex-direction: row">
          <p style="font-size: 15px; font-weight: 800;
            background: linear-gradient(45deg, #25F4EE, #FE2C55, #000000);
            -webkit-background-clip: text;
            color: transparent;">
            <ion-icon name="logo-tiktok" style="font-size: 15px; margin-right: 5px; color: #a61028;"></ion-icon>
            TikTok
          </p>
        </div>
        <ion-button fill="outline" class="connect-btn-homepage">
          Connect
          <ion-icon name="arrow-forward" style="font-size: 15px; color: #000000;"></ion-icon>
        </ion-button>
      </div>

      <!-- YouTube Connect Button -->
      <div class="social-connect-homepage">
        <div style="display: flex; flex-direction: row">
          <p style="font-size: 15px; font-weight: 800;
            background: linear-gradient(45deg, #FF0000, #CC0000, #990000);
            -webkit-background-clip: text;
            color: transparent;">
            <ion-icon name="logo-youtube" style="font-size: 15px; margin-right: 5px; color: #FF0000;"></ion-icon>
            YouTube
          </p>
        </div>
        <ion-button fill="outline" class="connect-btn-homepage">
          Connect
          <ion-icon name="arrow-forward" style="font-size: 15px; color: #000000;"></ion-icon>
        </ion-button>
      </div>
    </div>
  </div>

  <div class="stats-grid">
    <div style="display: flex; flex-direction: row; justify-content: space-between">
      <h2 style="font-size: 20px; font-weight: 800; color: white; text-align: left; padding-left: 10px"> Opportunities </h2>
      <button style="border-radius: 10px; border: 2px solid white; background: none; padding: 0 10px; color: white">Refresh</button>
    </div>

    <!-- Opportunity Card 1 -->
    <div class="stat-card">
      <div class="card-header">
        <ion-icon name="briefcase" style="color: var(--accent); font-size: 24px;"></ion-icon>
        <span style="font-size: 15px; font-weight: 800; color: var(--accent);">Sponsored Posts</span>
      </div>
      <div class="card-content">
        <p style="display: flex; flex-direction: row; justify-content: space-between; background: var(--accent); border-radius: 10px; padding: 10px; color: white; margin: 3px">
          <span>
            <ion-icon name="megaphone" style="font-size: 18px; color: white;"></ion-icon>
          <strong>Active Offers:</strong>
          </span>
          <span>3</span>
        </p>
        <p style="display: flex; flex-direction: row; justify-content: space-between; background: var(--accent); border-radius: 10px; padding: 10px; color: white; margin: 3px">
          <span>
            <ion-icon name="cash" style="font-size: 18px; color: white;"></ion-icon>
          <strong>Estimated Revenue:</strong>
          </span>
          <span>$1,250</span>
        </p>
        <p style="display: flex; flex-direction: row; justify-content: space-between; background: var(--accent); border-radius: 10px; padding: 10px; color: white; margin: 3px">
          <span>
            <ion-icon name="time" style="font-size: 18px; color: white;"></ion-icon>
          <strong>Next Deadline:</strong>
          </span>
          <span>Nov 25, 2024</span>
        </p>
      </div>
    </div>

    <!-- Opportunity Card 2 -->
    <div class="stat-card">
      <div class="card-header">
        <ion-icon name="star" style="color: var(--accent); font-size: 24px;"></ion-icon>
        <span style="font-size: 15px; font-weight: 800; color: var(--accent);">Brand Collabs</span>
      </div>
      <div class="card-content">
        <p style="display: flex; flex-direction: row; justify-content: space-between; background: var(--accent); border-radius: 10px; padding: 10px; color: white; margin: 3px">
          <span>
            <ion-icon name="ribbon" style="font-size: 18px; color: white;"></ion-icon>
          <strong>Pending Offers:</strong>
          </span>
          <span>2</span>
        </p>
        <p style="display: flex; flex-direction: row; justify-content: space-between; background: var(--accent); border-radius: 10px; padding: 10px; color: white; margin: 3px">
          <span>
            <ion-icon name="cash" style="font-size: 18px; color: white;"></ion-icon>
          <strong>Max Offer:</strong>
          </span>
          <span>$500</span>
        </p>
        <p style="display: flex; flex-direction: row; justify-content: space-between; background: var(--accent); border-radius: 10px; padding: 10px; color: white; margin: 3px">
          <span>
            <ion-icon name="calendar" style="font-size: 18px; color: white;"></ion-icon>
          <strong>Next Meeting:</strong>
          </span>
          <span>Nov 23, 2024</span>
        </p>
      </div>
    </div>

    <!-- Opportunity Card 3 -->
    <div class="stat-card">
      <div class="card-header">
        <ion-icon name="pricetags" style="color: var(--accent); font-size: 24px;"></ion-icon>
        <span style="font-size: 15px; font-weight: 800; color: var(--accent);">Affiliate Deals</span>
      </div>
      <div class="card-content">
        <p style="display: flex; flex-direction: row; justify-content: space-between; background: var(--accent); border-radius: 10px; padding: 10px; color: white; margin: 3px">
          <span>
            <ion-icon name="cart" style="font-size: 18px; color: white;"></ion-icon>
          <strong>Active Affiliates:</strong>
          </span>
          <span>5</span>
        </p>
        <p style="display: flex; flex-direction: row; justify-content: space-between; background: var(--accent); border-radius: 10px; padding: 10px; color: white; margin: 3px">
          <span>
            <ion-icon name="cash" style="font-size: 18px; color: white;"></ion-icon>
          <strong>Revenue Generated:</strong>
          </span>
          <span>$2,300</span>
        </p>
        <p style="display: flex; flex-direction: row; justify-content: space-between; background: var(--accent); border-radius: 10px; padding: 10px; color: white; margin: 3px">
          <span>
            <ion-icon name="analytics" style="font-size: 18px; color: white;"></ion-icon>
          <strong>Top Performing:</strong>
          </span>
          <span>Product X</span>
        </p>
      </div>
    </div>
  </div>

  <div class="stats-grid">
    <div style="display: flex; flex-direction: row; justify-content: space-between">
      <h2 style="font-size: 20px; font-weight: 800; color: white; text-align: left; padding-left: 10px"> Performance </h2>
      <button style="border-radius: 10px; border: 2px solid white; background: none; padding: 0 10px; color: white">Refresh</button>
    </div>

    <!-- Instagram Card -->
    <div class="stat-card">
      <div class="card-header">
<!--        <ion-icon name="logo-instagram" style="font-size: 25px; margin-right: 5px;color: #D62976FF;"></ion-icon>-->
        <span style="font-size: 15px; font-weight: 800;
                     background: linear-gradient(45deg, #feda75, #fa7e1e, #d62976, #962fbf, #4f5bd5);
                     -webkit-background-clip: text;
                     color: transparent;">
            Instagram
        </span>
      </div>
      <div class="card-content">
        <p style="display: flex; flex-direction: row; justify-content: space-between; background: var(--accent); border-radius: 10px; padding: 10px; color: white; margin: 3px">
          <span>
            <ion-icon name="people" style="color: white; font-size: 18px;"></ion-icon>
            <strong>Followers</strong>
          </span>
          <span>
            12,340
            <span>
                <ion-icon name="arrow-up" style="color: #00FF00; font-size: 20px;"></ion-icon>
            </span>
          </span>
        </p>
        <p style="display: flex; flex-direction: row; justify-content: space-between; background: var(--accent); border-radius: 10px; padding: 10px; color: white; margin: 3px">
          <span>
            <ion-icon name="pulse" style="color: white; font-size: 18px;"></ion-icon>
            <strong>Engagement Rate</strong>
          </span>
          <span>
            6.8%
            <span>
                <ion-icon name="arrow-down" style="color: #FF0000; font-size: 20px;"></ion-icon>
            </span>
          </span>
        </p>
        <p style="display: flex; flex-direction: row; justify-content: space-between; background: var(--accent); border-radius: 10px; padding: 10px; color: white; margin: 3px">
          <span>
            <ion-icon name="briefcase" style="color: white; font-size: 18px;"></ion-icon>
            <strong>Recent Campaigns</strong>
          </span>
          <span>
            0
            <span>
                <ion-icon name="arrow-down" style="color: #FF0000; font-size: 20px;"></ion-icon>
            </span>
          </span>
        </p>
        <p style="display: flex; flex-direction: row; justify-content: space-between; background: var(--accent); border-radius: 10px; padding: 10px; color: white; margin: 3px">
          <span>
            <ion-icon name="cash" style="color: white; font-size: 18px;"></ion-icon>
            <strong>Total Earnings</strong>
          </span>
          <span>
            RON 950
            <span>
                <ion-icon name="arrow-up" style="color: #00FF00; font-size: 20px;"></ion-icon>
            </span>
          </span>
        </p>
      </div>
<!--      <ion-button fill="clear" expand="block" style="color: #ffffff; font-weight: bold; background: var(&#45;&#45;accent); border-radius: 10px">View Details</ion-button>-->
    </div>


    <!-- TikTok Card -->
    <div class="stat-card">
      <div class="card-header">
        <!--        <ion-icon name="logo-instagram" style="font-size: 25px; margin-right: 5px;color: #D62976FF;"></ion-icon>-->
        <span style="font-size: 15px; font-weight: 800;
							background: linear-gradient(45deg, #25F4EE, #FE2C55, #000000);
							-webkit-background-clip: text;
							color: transparent;">
						  TikTok
					   	</span>
      </div>
      <div class="card-content">
        <p style="display: flex; flex-direction: row; justify-content: space-between; background: var(--accent); border-radius: 10px; padding: 10px; color: white; margin: 3px">
          <span>
            <ion-icon name="people" style="color: white; font-size: 18px;"></ion-icon>
            <strong>Followers</strong>
          </span>
          <span>
            12,340
            <span>
                <ion-icon name="arrow-up" style="color: #00FF00; font-size: 20px;"></ion-icon>
            </span>
          </span>
        </p>
        <p style="display: flex; flex-direction: row; justify-content: space-between; background: var(--accent); border-radius: 10px; padding: 10px; color: white; margin: 3px">
          <span>
            <ion-icon name="pulse" style="color: white; font-size: 18px;"></ion-icon>
            <strong>Engagement Rate</strong>
          </span>
          <span>
            6.8%
            <span>
                <ion-icon name="arrow-down" style="color: #FF0000; font-size: 20px;"></ion-icon>
            </span>
          </span>
        </p>
        <p style="display: flex; flex-direction: row; justify-content: space-between; background: var(--accent); border-radius: 10px; padding: 10px; color: white; margin: 3px">
          <span>
            <ion-icon name="briefcase" style="color: white; font-size: 18px;"></ion-icon>
            <strong>Recent Campaigns</strong>
          </span>
          <span>
            0
            <span>
                <ion-icon name="arrow-down" style="color: #FF0000; font-size: 20px;"></ion-icon>
            </span>
          </span>
        </p>
        <p style="display: flex; flex-direction: row; justify-content: space-between; background: var(--accent); border-radius: 10px; padding: 10px; color: white; margin: 3px">
          <span>
            <ion-icon name="cash" style="color: white; font-size: 18px;"></ion-icon>
            <strong>Total Earnings</strong>
          </span>
          <span>
            RON 950
            <span>
                <ion-icon name="arrow-up" style="color: #00FF00; font-size: 20px;"></ion-icon>
            </span>
          </span>
        </p>
      </div>
      <!--      <ion-button fill="clear" expand="block" style="color: #ffffff; font-weight: bold; background: var(&#45;&#45;accent); border-radius: 10px">View Details</ion-button>-->
    </div>

    <!-- YouTube Card -->
    <div class="stat-card">
      <div class="card-header">
        <!--        <ion-icon name="logo-instagram" style="font-size: 25px; margin-right: 5px;color: #D62976FF;"></ion-icon>-->
        <span style="font-size: 15px; font-weight: 800;
									background: linear-gradient(45deg, #FF0000, #CC0000, #990000);
									-webkit-background-clip: text;
									color: transparent;">
						  YouTube
   						</span>
      </div>
      <div class="card-content">
        <p style="display: flex; flex-direction: row; justify-content: space-between; background: var(--accent); border-radius: 10px; padding: 10px; color: white; margin: 3px">
          <span>
            <ion-icon name="people" style="color: white; font-size: 18px;"></ion-icon>
            <strong>Followers</strong>
          </span>
          <span>
            12,340
            <span>
                <ion-icon name="arrow-up" style="color: #00FF00; font-size: 20px;"></ion-icon>
            </span>
          </span>
        </p>
        <p style="display: flex; flex-direction: row; justify-content: space-between; background: var(--accent); border-radius: 10px; padding: 10px; color: white; margin: 3px">
          <span>
            <ion-icon name="pulse" style="color: white; font-size: 18px;"></ion-icon>
            <strong>Engagement Rate</strong>
          </span>
          <span>
            6.8%
            <span>
                <ion-icon name="arrow-down" style="color: #FF0000; font-size: 20px;"></ion-icon>
            </span>
          </span>
        </p>
        <p style="display: flex; flex-direction: row; justify-content: space-between; background: var(--accent); border-radius: 10px; padding: 10px; color: white; margin: 3px">
          <span>
            <ion-icon name="briefcase" style="color: white; font-size: 18px;"></ion-icon>
            <strong>Recent Campaigns</strong>
          </span>
          <span>
            0
            <span>
                <ion-icon name="arrow-down" style="color: #FF0000; font-size: 20px;"></ion-icon>
            </span>
          </span>
        </p>
        <p style="display: flex; flex-direction: row; justify-content: space-between; background: var(--accent); border-radius: 10px; padding: 10px; color: white; margin: 3px">
          <span>
            <ion-icon name="cash" style="color: white; font-size: 18px;"></ion-icon>
            <strong>Total Earnings</strong>
          </span>
          <span>
            RON 950
            <span>
                <ion-icon name="arrow-up" style="color: #00FF00; font-size: 20px;"></ion-icon>
            </span>
          </span>
        </p>
      </div>
      <!--      <ion-button fill="clear" expand="block" style="color: #ffffff; font-weight: bold; background: var(&#45;&#45;accent); border-radius: 10px">View Details</ion-button>-->
    </div>
  </div>
</ion-content>

<ion-footer>
  <div class="bottom-tabs" >
    <button (click)="home()" class="selected-tab">
      <ion-icon name="home" style="font-size: 30px;"></ion-icon>
    </button>
    <button style="position:relative" (click)="offers()">
      <ion-icon name="megaphone" style="font-size: 30px;"></ion-icon>
      <div *ngIf="offers_count" class="badge-notification">{{offers_count}}</div>
    </button>
    <button (click)="archive()">
      <ion-icon name="archive" style="font-size: 30px;"></ion-icon>
    </button>
    <button (click)="profile()">
      <ion-icon name="person-circle" style="font-size: 30px;"></ion-icon>
    </button>
  </div>
</ion-footer>