<div *ngIf="userStats">
    <h3>User Statistics</h3>
    <pre>{{ userStats | json }}</pre>
</div>

<div *ngIf="audienceDemographics">
    <h3>Audience Demographics</h3>
    <pre>{{ audienceDemographics | json }}</pre>
</div>

<div *ngIf="engagementMetrics">
    <h3>Engagement Metrics</h3>
    <pre>{{ engagementMetrics | json }}</pre>
</div>
