import {Component, ViewChild, OnInit, NgZone, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {NavController, AlertController, Platform, IonicModule} from '@ionic/angular';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {UserProfile, UserService} from "../../services/user.service";
import {printGroup, ApiService, StorageService, Settings} from "../../services/providers.service";
//import {} from '@types/google.maps';
import $ from "jquery";
import { EventsService } from '../../services/events.service';
import { ActivatedRoute } from '@angular/router';
import {environment } from '../../../../custom/environment';
import {CommonModule} from "@angular/common";
import {ProfileDetailsPageRoutingModule} from "./profile-details-routing.module";

@Component({
  selector: 'app-profile-details',
  templateUrl: './profile-details.page.html',
  styleUrls: ['./profile-details.page.scss'],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    ProfileDetailsPageRoutingModule,
    TranslateModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ProfileDetailsPage implements OnInit {
    formgroup:FormGroup;
    isTikTokModalOpen = false;
    isYouTubeModalOpen = false;
    selectedTab: string = 'general';
    offers_count: number;
    city:any;
    country: string = "";
    google:any;
    user: any;
    type: string;
    interests:any;
    interests_code: string = "";
    language: string = "en";
    date: string = "";
    interests_valid: boolean = false;
    missing_interest: boolean = false;
    languageSelectOptions:any;
    isIOS = false;
    getPaidThroughCompany: boolean = false;

  constructor(
      public navCtrl: NavController,
      public formbuilder: FormBuilder,
      public alertCtrl: AlertController,
      public events: EventsService,
      public platform: Platform,
      public ngZone: NgZone,
      private apiService: ApiService,
      private storage: StorageService,
      private userService: UserService,
      private route: ActivatedRoute,
      private translate:TranslateService
  ) {
      this.offers_count = this.route.snapshot.params['offersCount'];
      this.storage.get('language').then(su => { if (su){ this.language = su; }})
      this.languageSelectOptions = {
          title: this.translate.instant('change-language'),
          mode:'ios'
      }
      this.isIOS = platform.is('ios');
  }

    companyFormGroup = this.formbuilder.group({
        cif: ['', Validators.required],
        name: ['', Validators.required],
        reg_com: [''],
        address: ['', Validators.required],
        locality: ['', Validators.required],
        county: ['', Validators.required],
        country: ['Romania', Validators.required],
        iban: ['', Validators.required],
        bank: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        contact_person: [''],
        phone: [''],
        vat_payer: ['no', Validators.required],
    });

    ionViewDidLoad() {
      window["app_ga"]('send', 'pageview', "profile_details");
    }

    selectTab(tab: string) {
        this.selectedTab = tab;
    }


    ionViewWillEnter() {
      $(document).on({
          'DOMNodeInserted': function () {
              $('.pac-item, .pac-item span', this).addClass('needsclick');
          }
      }, '.pac-container');
      // let input = $(".addressAC").last().find("input")[0];
      // let options = {
      //     types: ['(cities)'],
      //     componentRestrictions: {country: this.country}
      // };
      // let autocomplete = new google.maps.places.Autocomplete(input, options);
      // google.maps.event.addListener(autocomplete, "place_changed", () => {
      //     this.ngZone.run(() => {
      //         let place: google.maps.places.PlaceResult = autocomplete.getPlace();

      //         if(place.geometry === undefined || place.geometry === null){
      //             return;
      //         }

      //         if (place.vicinity){
      //             this.city = place.vicinity;
      //             input.value = place.vicinity;
      //             this.formgroup.controls.city.patchValue(input.value)

      //         } else {
      //             this.city = place.formatted_address.split(',')[0];
      //             input.value = place.formatted_address.split(',')[0];
      //             this.formgroup.controls.city.patchValue(input.value)
      //         }
      //     })
      // });
  }

  ngOnInit() {
      this.userService.userDetails$.subscribe(
          (user: UserProfile) => {
              this.user    = user;
              this.city    = user.city;
              this.country = user.country_code;
              this.date    = user.birthdate;

              let selected_interests = [];

              user.interests.forEach((post, index) => {
                  selected_interests.push(post.id);
              });


              this.formgroup = this.formbuilder.group({
                  fullname: [user.fullname, Validators.required],
                  city: [user.city, Validators.required],
                  birthdate: [user.birthdate, Validators.required],
                  email: [user.email, Validators.required],
                  bank_number: [user.bank_number],
                  bank_name: [user.bank_name],
                  address: [user.address, Validators.required],
                  postal_code: [user.postal_code, Validators.required],
                  uid: [user.uid],
                  tiktok_name: [user.tiktok_name],
                  youtube_id: [user.youtube_id],
                  interests: [null]
              });
                this.interests = [
                  {value: "art",       title: this.translate.instant('interests.art'),         selected: selected_interests.indexOf(1) > -1,  id: "1" },
                  {value: "beauty",    title: this.translate.instant('interests.beauty'),      selected: selected_interests.indexOf(2) > -1,  id: "2" },
                  {value: "design",    title: this.translate.instant('interests.design'),      selected: selected_interests.indexOf(3) > -1,  id: "3" },
                  {value: "fashion",   title: this.translate.instant('interests.fashion'),     selected: selected_interests.indexOf(4) > -1,  id: "4" },
                  {value: "foodies",   title: this.translate.instant('interests.foodies'),     selected: selected_interests.indexOf(5) > -1,  id: "5" },
                  {value: "lifestyle", title: this.translate.instant('interests.lifestyle'),   selected: selected_interests.indexOf(6) > -1,  id: "6" },
                  {value: "mommies",   title: this.translate.instant('interests.mommies'),     selected: selected_interests.indexOf(7) > -1,  id: "7" },
                  {value: "music",     title: this.translate.instant('interests.music'),       selected: selected_interests.indexOf(8) > -1,  id: "8" },
                  {value: "sports",    title: this.translate.instant('interests.sports'),      selected: selected_interests.indexOf(9) > -1,  id: "9" },
                  {value: "travel",    title: this.translate.instant('interests.travel'),      selected: selected_interests.indexOf(10) > -1,  id: "10" }
               ];
          },
          er2 => {
              printGroup("RegisterForm er", er2);
          }
      )
  }

    toggleGetPaidThroughCompany() {
        this.getPaidThroughCompany = !this.getPaidThroughCompany;
        if (!this.getPaidThroughCompany) {
            this.companyFormGroup.reset(); // Reset the form if unchecked
        }
    }

    saveCompanyDetails() {
        if (this.companyFormGroup.valid) {
            const companyDetails = this.companyFormGroup.value;

            // Ensure the entered email is not the same as the user's profile email
            if (companyDetails.email === this.user.email) {
                this.alertCtrl
                    .create({
                        header: this.translate.instant('Error'),
                        message: this.translate.instant('Company email must differ from profile email'),
                        buttons: ['OK'],
                    })
                    .then((alert) => alert.present());
                return;
            }

            console.log('Company Details:', companyDetails);

            // Call backend to save details
            // this.apiService.saveCompanyDetails(companyDetails).then(
            //     (response) => {
            //         this.alertCtrl
            //             .create({
            //                 header: this.translate.instant('Success'),
            //                 message: this.translate.instant('Company details saved successfully!'),
            //                 buttons: ['OK'],
            //             })
            //             .then((alert) => alert.present());
            //     },
            //     (error) => {
            //         this.alertCtrl
            //             .create({
            //                 header: this.translate.instant('Error'),
            //                 message: this.translate.instant('Failed to save company details'),
            //                 buttons: ['OK'],
            //             })
            //             .then((alert) => alert.present());
            //     }
            // );
        } else {
            this.alertCtrl
                .create({
                    header: this.translate.instant('Error'),
                    message: this.translate.instant('Please complete all required fields'),
                    buttons: ['OK'],
                })
                .then((alert) => alert.present());
        }
    }


    async updateInterestSelection(ind) {
        var count = 0;
        for(var i in this.interests){
            count += this.interests[i].selected?1:0;
        }
        if (count < 3 || this.interests[ind].selected) {
            this.interests[ind].selected=!this.interests[ind].selected;
            if (this.interests[ind].selected) {
                count++;
            } else {
                count--;
            }
        } else {
            let alert = await this.alertCtrl.create({
                header:  this.translate.instant('interests.error.title'),
                subHeader: this.translate.instant('interests.error.message'),
                buttons: [this.translate.instant('interests.error.button')],
                mode: 'ios'
            });
            alert.present();
        }

        if (count === 0){
            this.missing_interest = true;
        } else {
            this.missing_interest = false;
        }
        this.interests_valid =  count < 4;
    }

    archive(){
        this.type = 'old';
        this.navCtrl.navigateRoot('/campaign-offers/old');
    }

    home() {
        this.navCtrl.navigateRoot('/homepage/' + this.offers_count);
    }

    offers(){
        this.type = 'offers';
        this.navCtrl.navigateRoot('/campaign-offers/offers');
    }

    openModal(platform: string) {
        if (platform === 'tiktok') {
            this.isTikTokModalOpen = true;
        } else if (platform === 'youtube') {
            this.isYouTubeModalOpen = true;
        }
    }

    closeModal(platform: string) {
        if (platform === 'tiktok') {
            this.isTikTokModalOpen = false;
        } else if (platform === 'youtube') {
            this.isYouTubeModalOpen = false;
        }
    }

    logout(){
      this.storage.clear().then(su => {
          this.storage.pStore('language', this.language).then(su => {
              this.navCtrl.navigateRoot('/signin');
          });
      });
    }

    changeLanguage() {
      this.events.publish("translations:change", this.language);
      setTimeout(()=> {
           this.interests.find(x => x.id == 1).title = this.translate.instant('interests.art');
           this.interests.find(x => x.id == 2).title = this.translate.instant('interests.beauty');
           this.interests.find(x => x.id == 3).title = this.translate.instant('interests.design');
           this.interests.find(x => x.id == 4).title = this.translate.instant('interests.fashion');
           this.interests.find(x => x.id == 5).title = this.translate.instant('interests.foodies');
           this.interests.find(x => x.id == 6).title = this.translate.instant('interests.lifestyle');
           this.interests.find(x => x.id == 7).title = this.translate.instant('interests.mommies');
           this.interests.find(x => x.id == 8).title = this.translate.instant('interests.music');
           this.interests.find(x => x.id == 9).title = this.translate.instant('interests.sports');
           this.interests.find(x => x.id == 10).title = this.translate.instant('interests.travel');
           this.storage.store('language', this.language);
          this.languageSelectOptions.title =this.translate.instant('change-language');

      }, 100);
        this.apiService.update_language({email: this.user.email, lang: this.language}).then(
            success => {},
            error2 => {}
        );
     }

    updateDetails(){
        let data = this.formgroup.getRawValue();
        data.interests = [];

        for(var i in this.interests){
            if ( this.interests[i].selected){
                data.interests.push(this.interests[i])
            }
        }

        this.apiService.updateLeader(data).then(
            success => {
                this.apiService.login().then(
                    (success: any) => {
                        this.apiService.setLeader(success.message);
                    },
                    error2 => {
                    }
                );
            }
        );
    }
    async deleteLeader(){
        let title = this.translate.instant   ('profile-details.delete-title'),
            message = this.translate.instant ('profile-details.delete-message'),
            confirm = this.translate.instant ('profile-details.delete-button'),
            alert = await this.alertCtrl.create({
                header: title,
                subHeader: message,
                buttons: [
                    {
                      text: this.translate.instant('campaign.action-alert.buttons.cancel'),
                      role: 'cancel'
                    },
                    { text: confirm,
                        handler: () => {
                          this.apiService.deleteLeader().then(
                            success => {
                              this.storage.clear().then(su => {
                                this.storage.pStore('language', this.language).then(su => {
                                    this.navCtrl.navigateRoot('/signin');
                                });
                            });
                          }
                        );
                      }
                    }
                ],
                mode: 'ios'
            });
        alert.present();

    }
}
