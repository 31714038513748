import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CampaignOffersPage } from './campaign-offers.page';

const routes: Routes = [
  {
    path: 'campaign-offers',
    component: CampaignOffersPage
  },
  {
    path: 'campaign-offers/:type',
    component: CampaignOffersPage
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CampaignOffersPageRoutingModule {}
