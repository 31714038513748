import { IgLoginPage } from './pages/ig-login/ig-login.page';
import { TncFramePage } from './pages/tnc-frame/tnc-frame.page';
import { SignupPage } from './pages/signup/signup.page';
import { PendingApprovalPage } from './pages//pending-approval/pending-approval.page';
import { PasswordReminderPage } from './pages/password-reminder/password-reminder.page';
import { CampaignOffersPage } from './pages/campaign-offers/campaign-offers.page';
import { HomepagePage } from "./pages/homepage/homepage.page";
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CampaignPage } from './pages/campaign/campaign.page';
import { ProfileDetailsPage } from './pages/profile-details/profile-details.page';
import { AddMediaPage } from './pages/add-media/add-media.page';
import { MediaPage } from './pages/media/media.page';
import {SigninPage} from "./pages/signin/signin.page";

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/signin/signin.module').then( m => m.SigninPageModule)
  },
  {
    path: 'add-media/:platform/:id',
    component: AddMediaPage,
    loadChildren: () => import('./pages/add-media/add-media.module').then( m => m.AddMediaPageModule)
  },
  {
    path: 'campaign/:id',
    component: CampaignPage,
    pathMatch: 'full',
    loadChildren: () => import('./pages/campaign/campaign.module').then( m => m.CampaignPageModule)
  },
  {
    path: 'campaign-offers',
    component: CampaignOffersPage,
    loadChildren: () => import('./pages/campaign-offers/campaign-offers.module').then( m => m.CampaignOffersPageModule)
  },
  {
    path: 'campaign-offers/:type',
    component: CampaignOffersPage,
    loadChildren: () => import('./pages/campaign-offers/campaign-offers.module').then( m => m.CampaignOffersPageModule)
  },
  {
    path: 'ig-verify',
    component: IgLoginPage,
    loadChildren: () => import('./pages/ig-login/ig-login.module').then( m => m.IgLoginPageModule)
  },
  {
    path: 'invoice/:payout/:id',
    loadChildren: () => import('./pages/invoice/invoice.module').then( m => m.InvoicePageModule)
  },
  {
    path: 'media/:id',
    component: MediaPage,
    loadChildren: () => import('./pages/media/media.module').then( m => m.MediaPageModule)
  },
  {
    path: 'password-reminder',
    component: PasswordReminderPage,
    loadChildren: () => import('./pages/password-reminder/password-reminder.module').then( m => m.PasswordReminderPageModule)
  },
  {
    path: 'pending-approval',
    component: PendingApprovalPage,
    loadChildren: () => import('./pages/pending-approval/pending-approval.module').then( m => m.PendingApprovalPageModule)
  },
  {
    path: 'profile/:offersCount',
    component: ProfileDetailsPage,
    loadChildren: () => import('./pages/profile-details/profile-details.module').then( m => m.ProfileDetailsPageModule)
  },
  {
    path: 'tnc',
    component: TncFramePage,
    loadChildren: () => import('./pages/tnc-frame/tnc-frame.module').then( m => m.TncFramePageModule)
  },
  {
    path: 'signin',
    component: SigninPage,
    loadChildren: () => import('./pages/signin/signin.module').then( m => m.SigninPageModule)
  },
  {
    path: 'signup',
    component: SignupPage,
    loadChildren: () => import('./pages/signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'signup-details',
    component: SignupPage,
    loadChildren: () => import('./pages/signup-details/signup-details.module').then( m => m.SignupDetailsPageModule)
  },
  {
    path: 'homepage',
    component: HomepagePage,
    loadChildren: () => import('./pages/homepage/homepage.module').then(m => m.HomepagePageModule)
  },
  {
    path: 'homepage/:offersCount',
    component: HomepagePage,
    loadChildren: () => import('./pages/homepage/homepage.module').then(m => m.HomepagePageModule)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
